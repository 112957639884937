export const CASE_STATUS = {
  COMPLETE_PATIENT_RECORDS: "COMPLETE_PATIENT_RECORDS",
  PRESETUP_IN_PROGRESS: "PRESETUP_IN_PROGRESS",
  FILL_OUT_RX_FORM: "FILL_OUT_RX_FORM",
  SUBMIT_TO_UASSIST: "SUBMIT_TO_UASSIST",
  SUBMITTED_TO_UASSIST: "SUBMITTED_TO_UASSIST",
  PLANNING_IN_PROGRESS: "PLANNING_IN_PROGRESS",
  REVIEW_PLAN: "REVIEW_PLAN",
  MODIFICATION_REQUESTED: "MODIFICATION_REQUESTED",
  REVIEW_MODIFICATION: "REVIEW_MODIFICATION",
  MODIFICATION_IN_PROGRESS: "MODIFICATION_IN_PROGRESS",
  PLACE_ORDER: "PLACE_ORDER",
  ATTENTION_NEEDED: "ATTENTION_NEEDED",
  IN_PRODUCTION: "IN_PRODUCTION",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  ORDER_SUBMITTED: "ORDER_SUBMITTED",
  TREATMENT_IN_PROGRESS: "TREATMENT_IN_PROGRESS",
  TREATMENT_COMPLETE: "TREATMENT_COMPLETE",
  ARCHIVED: "ARCHIVED",
  CANCELLED: "CANCELLED",
  SUBMITTED_TO_UASSIST_REFINEMENT: "SUBMITTED_TO_UASSIST_REFINEMENT",
  PLANNING_IN_PROGRESS_REFINEMENT: "PLANNING_IN_PROGRESS_REFINEMENT",
  ATTENTION_NEEDED_REFINEMENT: "ATTENTION_NEEDED_REFINEMENT",
  FROM_UDESIGN: "FROM_UDESIGN",
  REJECTED: "REJECTED",

  DEFAULT: "",
}

export const STATUS = [
  {
    key: CASE_STATUS.ATTENTION_NEEDED,
    displayText: "Attention Needed",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "warning.main",
      variant: "filled",
    },
  },
  {
    key: CASE_STATUS.REJECTED,
    displayText: "Rejected",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "error.main",
      variant: "filled",
    },
  },
  {
    key: CASE_STATUS.REVIEW_PLAN,
    displayText: "Review Plan",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "success.main",
      variant: "filled",
    },
  },
  {
    key: CASE_STATUS.REVIEW_MODIFICATION,
    displayText: "Review Modification",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "success.main",
      variant: "filled",
    },
    showAdditionalData: true,
  },
  {
    key: CASE_STATUS.PLACE_ORDER,
    displayText: "Place Order",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "success.main",
      variant: "filled",
    },
  },
  {
    key: CASE_STATUS.SUBMIT_TO_UASSIST,
    displayText: "Submit to uAssist",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "info.main",
      variant: "filled",
      width: 195,
    },
  },
  {
    key: CASE_STATUS.FILL_OUT_RX_FORM,
    displayText: "Fill Out Rx Form",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "info.main",
      variant: "filled",
      width: 248,
    },
  },
  {
    key: CASE_STATUS.COMPLETE_PATIENT_RECORDS,
    displayText: "Complete Patient Records",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "info.main",
      variant: "filled",
      width: 268,
    },
  },
  {
    key: CASE_STATUS.DELIVERED,
    displayText: "Delivered",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.SHIPPED,
    displayText: "Shipped",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.IN_PRODUCTION,
    displayText: "In Production",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.ORDER_SUBMITTED,
    displayText: "Order Submitted",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.PLANNING_IN_PROGRESS,
    displayText: "Planning in Progress",
    style: {
      color: "info.main",
      border: "1px solid",
      borderColor: "info.main",
      variant: "outlined",
    },
  },
  {
    key: CASE_STATUS.MODIFICATION_IN_PROGRESS,
    displayText: "Modification in Progress",
    style: {
      color: "info.main",
      border: "1px solid",
      borderColor: "info.main",
      variant: "outlined",
    },
  },
  {
    key: CASE_STATUS.MODIFICATION_REQUESTED,
    displayText: "Modification Requested",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.SUBMITTED_TO_UASSIST,
    displayText: "Submitted to uAssist",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.TREATMENT_IN_PROGRESS,
    displayText: "Treatment in Progress",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.TREATMENT_COMPLETE,
    displayText: "Treatment Complete",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.ARCHIVED,
    displayText: "Archived",
    style: {
      variant: "filled",
      color: "text.primary",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  {
    key: CASE_STATUS.FROM_UDESIGN,
    isDisableDropdownShow: false,
    displayText: "From uDesign",
    style: {
      color: "rgba(255, 255, 255, 1)",
      variant: "filled",
      backgroundColor: "#9C27B0",
    },
  },
  {
    key: CASE_STATUS.CANCELLED,
    displayText: "Cancelled",
    style: {
      variant: "filled",
      color: "text.primary",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  {
    key: CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
    isDisableDropdownShow: true,
    displayText: "Submitted to uAssist",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
    isDisableDropdownShow: true,
    displayText: "Attention Needed",
    style: {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "warning.main",
      variant: "filled",
    },
  },
  {
    key: CASE_STATUS.PLANNING_IN_PROGRESS_REFINEMENT,
    isDisableDropdownShow: true,
    displayText: "Planning in Progress",
    style: {
      color: "info.main",
      border: "1px solid",
      borderColor: "info.main",
      variant: "outlined",
    },
  },
]

export const ORDER_LIST_STATUS: any[] = [
  {
    key: 50,
    displayText: "Delivered",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: 40,
    displayText: "Shipped",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: 30,
    displayText: "In Production",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
  {
    key: 20,
    displayText: "Order Submitted",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "info.main",
    },
  },
]

export const getStatusObjByKey = (value: string) => {
  let status = STATUS.filter((status) => status.key === value)
  if (status.length === 0) {
    status = STATUS.filter(
      (status) => status.key === CASE_STATUS.COMPLETE_PATIENT_RECORDS,
    )
  }
  return status[0] || []
}
