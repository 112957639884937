import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { isEqual, uniq } from "lodash"

import { UText } from "../../../../../components"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "../../../../../components/mui.components"
import { useAppSelector } from "../../../../../core/app/hooks"
import {
  OrderFilterQueryParams,
  PatientFilterQueryParams,
} from "../../../../../core/app/slices/patients/patient.types"
import { RootState } from "../../../../../core/app/store"
import { CASE_STATUS, ORDER_LIST_STATUS, STATUS } from "../../../config/status.config"
import { ORDER_TYPE, STATUS_UPDATED } from "../../filter"

import UStatusFilter from "./UStatusFilter"
import { UTableFilterList } from "./UTableFilterList"
import UTableSearch from "./UTableSearch"

const UTableFilter: FC<{
  onFilterUpdate?: (
    fields: PatientFilterQueryParams | OrderFilterQueryParams,
  ) => void
  filterQuery: any
  onSearch?: (val: string) => void
  searchText?: string
  enableSearch?: boolean
  isOrderList?: boolean
  name?: string
}> = ({
  onFilterUpdate,
  filterQuery,
  onSearch,
  searchText,
  enableSearch,
  isOrderList,
  name,
}) => {
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  const dispatch = useDispatch()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"))

  const [filterQueryParams, setFilterQueryParams] =
    useState<PatientFilterQueryParams>({})
  const [orderFilterQueryParams, setOrderFilterQueryParams] =
    useState<OrderFilterQueryParams>({})
  const { t } = useTranslation()
  const [isShowArchivedPatients, setIsShowArchivedPatients] = useState(false)
  const [statusUpdatedFilter, setStatusUpdatedFilter] = useState("")
  const [orderDateFilter, setOrderDateFilter] = useState("")
  const [selectedOrderType, setSelectedOrderType] = useState([])
  const [assigners, setAssigners] = useState([])
  const [status, setStatus] = useState<any[]>([])
  const [selectedAssigners, setSelectedAssigners] = useState<
    (string | number)[]
  >([])
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string[]>([])

  useEffect(() => {
    if (filterQuery) {
      if (isOrderList) {
        handleOrderList(filterQuery)
      } else {
        handlePatientList(filterQuery)
      }
    }
  }, [filterQuery])

  const handleOrderList = (filterQuery) => {
    setOrderFilterQueryParams(filterQuery)
    setSelectedOrderStatus(
      filterQuery.status_filter?.length ? [...filterQuery.status_filter] : [],
    )
    setSelectedOrderType( (filterQuery?.bundle_filter?.length) ? filterQuery.bundle_filter : [])
    setOrderDateFilter(filterQuery.date_filter)
  }

  const handlePatientList = (filterQuery) => {
    setFilterQueryParams(filterQuery)
    setSelectedStatus(filterQuery.status?.length ? [...filterQuery.status] : [])
    setSelectedAssigners(
      filterQuery.assigned_to?.length ? [...filterQuery.assigned_to] : [],
    )
    setIsShowArchivedPatients(filterQuery.show_archived_patients === 1)
    setStatusUpdatedFilter(filterQuery.status_updated)
  }

  useEffect(() => {
    if (!isShowArchivedPatients) {
      const status = STATUS.filter(
        (status) => status.key !== "ARCHIVED" && !status.isDisableDropdownShow,
      )
      setStatus(status)
    } else {
      setStatus(STATUS)
    }
  }, [dispatch, isShowArchivedPatients])

  useEffect(() => {
    setAssigners(doctorList)
  }, [doctorList])

  useEffect(() => {
    if (isOrderList) {
      if (
        !isEqual(orderFilterQueryParams, filterQuery) &&
        Object.keys(orderFilterQueryParams).length
      ) {
        onFilterUpdate({
          ...orderFilterQueryParams,
        })
      }
    } else {
      if (
        !isEqual(filterQueryParams, filterQuery) &&
        Object.keys(filterQueryParams).length
      ) {
        onFilterUpdate({
          ...filterQueryParams,
        })
      }
    }
  }, [filterQueryParams, orderFilterQueryParams])

  const handleChange = (event: SelectChangeEvent, filterType: string) => {
    const updatedParams = { ...filterQueryParams }
    const updateOrderParams = { ...orderFilterQueryParams }
    if (filterType === "status") {
      const selectedStatuses = uniq(event.target.value)
      setSelectedStatus(selectedStatuses)
      updatedParams.status = selectedStatuses
    } else if (filterType === "assignedTo") {
      const selectedAssignees = uniq(event.target.value)
      setSelectedAssigners(selectedAssignees)
      updatedParams.assigned_to = selectedAssignees
    } else if (filterType === "updatedtime") {
      const updatedTime = event.target.value
      setStatusUpdatedFilter(updatedTime)
      updatedParams.status_updated = updatedTime
    } else if (filterType === "order_type") {
      const orderType = uniq(event.target.value)
      setSelectedOrderType(orderType)
      updateOrderParams.bundle_filter = orderType
    } else if (filterType === "orderStatus") {
      const selectedOrderStatus = uniq(event.target.value)
      setSelectedOrderStatus(selectedOrderStatus)
      updateOrderParams.status_filter = selectedOrderStatus
    } else if (filterType === "orderDate") {
      const orderDate = event.target.value
      setOrderDateFilter(orderDate)
      updateOrderParams.date_filter = orderDate
    }
    setFilterQueryParams(updatedParams)
    setOrderFilterQueryParams(updateOrderParams)
  }

  const handleCheckboxChange = () => {
    const showArchived = !isShowArchivedPatients
    setIsShowArchivedPatients(showArchived)
  
    let updatedFilterQuery = { ...filterQueryParams }
  
    if (!showArchived && updatedFilterQuery.status) {
      updatedFilterQuery.status = updatedFilterQuery.status.filter((a) => a !== CASE_STATUS.ARCHIVED)
    }
  
    const updatedParams = {
      ...updatedFilterQuery,
      show_archived_patients: showArchived ? 1 : 0,
    }
    setSelectedStatus(updatedParams.status?.length ? [...filterQuery.status] : [])
    setFilterQueryParams(updatedParams)
  }

  const renderStatusUpdatedValue = () => {
    if (!isOrderList) {
      return !statusUpdatedFilter
        ? t("filter.statusUpdated")
        : STATUS_UPDATED.find((s) => s.key === statusUpdatedFilter)?.displayText
    } else {
      return !orderDateFilter
        ? "Order Date"
        : STATUS_UPDATED.find((s) => s.key === orderDateFilter)?.displayText
    }
  }

  return (
    <Grid
      container
      columnGap={1}
      flexDirection={"row"}
      alignItems={"center"}
      flexWrap={"nowrap"}
      pt={"5px"}
    >
      {enableSearch && name === "Active orders" && (
        <>
          <Grid item sx={{ minWidth: "702px" }}>
            <UTableSearch onSearch={onSearch} searchText={searchText} />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UStatusFilter
              statusUpdatedFilter={orderDateFilter}
              dateFilterOptions={STATUS_UPDATED}
              onFilterChange={(e) => handleChange(e, "orderDate")}
              renderValue={renderStatusUpdatedValue}
            />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UTableFilterList
              selectedMenu={selectedOrderType}
              menuList={ORDER_TYPE}
              onFilterChange={handleChange}
              listStyle={{
                minWidth: "269px",
              }}
              placeHolder="Order Type"
              filterKey="order_type"
              label="Order Type"
            />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UTableFilterList
              selectedMenu={selectedOrderStatus}
              listStyle={{
                minWidth: "269px",
              }}
              menuList={ORDER_LIST_STATUS}
              onFilterChange={handleChange}
              placeHolder="Status"
              filterKey="orderStatus"
            />
          </Grid>
        </>
      )}
      {enableSearch && name === "Patient List" && (
        <>
          <Grid item sx={{ minWidth: "385px" }}>
            <UTableSearch onSearch={onSearch} searchText={searchText} />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UTableFilterList
              selectedMenu={selectedAssigners}
              menuList={assigners}
              onFilterChange={handleChange}
              listStyle={{
                minWidth: "272px",
              }}
              placeHolder={t("filter.assignedTo")}
              filterKey="assignedTo"
              label="Doctors"
            />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UTableFilterList
              selectedMenu={selectedStatus}
              listStyle={{
                minWidth: "272px",
              }}
              menuList={status}
              onFilterChange={handleChange}
              placeHolder={t("filter.status")}
              filterKey="status"
            />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <UStatusFilter
              statusUpdatedFilter={statusUpdatedFilter}
              dateFilterOptions={STATUS_UPDATED}
              onFilterChange={(e) => handleChange(e, "updatedtime")}
              renderValue={renderStatusUpdatedValue}
            />
          </Grid>
          <Grid item xs={isXs ? 12 : undefined}>
            <FormControlLabel
              sx={{
                ml: 0,
                minWidth: "275px",
                ".MuiTypography-root": {
                  fontSize: "16px",
                  color: "text.primary",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
              control={
                <Checkbox
                  checked={isShowArchivedPatients}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("filter.archivedPatients")}
            />
          </Grid>
        </>
      )}
      {enableSearch && name === "Org List" && (
        <Grid item sx={{ minWidth: "395px" }}>
          <UTableSearch onSearch={onSearch} searchText={searchText} />
        </Grid>
      )}
    </Grid>
  )
}

export default UTableFilter
