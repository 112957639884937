import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import { throttle } from "lodash"

import { Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { fetchOrganizationList } from "../../core/app/slices/organization/organizationThunkApi"
import { OrgQueryparams } from "../../core/app/slices/patients/patient.types"
import { RootState } from "../../core/app/store"
import { UText } from "../../ui-component/components"
import UTable from "../Patient/PatientList/components/UTable/UTable"
import {
  defaultOrgParams,
  orgTableData,
} from "../Patient/PatientList/patientTable/table.config"

import { updateCurrentOrgId } from "@/core/app/slices/user/userSlice"
import { useMyNavigation } from "@/hooks/useMyNavigation"

const OrganizationList: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const myNavigate = useMyNavigation()
  const { t } = useTranslation("common")
  const [tableQueryParams, setTableQueryParams] =
    useState<OrgQueryparams>(defaultOrgParams)
  const { user } = useAppSelector((state: RootState) => state.userService)
  useEffect(() => {
    dispatch(
      fetchOrganizationList({
        orgQueryparams: {
          ...tableQueryParams,
        },
      }),
    )
  }, [tableQueryParams])

  const { organizations, paging, loading } = useAppSelector(
    (state: RootState) => state.organizationService,
  )

  const handleChangePage = (newpage: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      page: newpage,
    })
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      page_size: newPageSize,
      page: 1,
    })
  }
  const throttleOnSearch = useCallback(
    throttle((value) => {
      setTableQueryParams({
        ...tableQueryParams,
        search: value,
      })
    }, 500),
    [],
  )

  const onSearch = (value: string) => {
    if (value !== "") {
      throttleOnSearch(value)
    } else {
      setTableQueryParams({ ...defaultOrgParams })
    }
  }
  return (
    <>
      <Grid container item display={"flex"} sx={{ mt: 3 }} py={"16px"}>
        <Grid item xs={8}>
          <UText variant={"h4"}>{t("organizationTitle")}</UText>
        </Grid>
        {/*Commenting these 2 buttons as per the ticket UA-3494 */}
        {/* <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/organization/wecaselist`)
            }}
          >
            WeDesign Patient List
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={() => {
              const myorg_id = user.my_orgId
              dispatch(updateCurrentOrgId(myorg_id))
              myNavigate(`/patients`, {}, myorg_id)
            }}
          >
            Myself Patient List
          </Button>
        </Grid> */}
      </Grid>
      <Grid item container justifyContent={"center"}>
        <UTable
          filter={defaultOrgParams}
          columnData={organizations}
          columnDef={orgTableData.columnDef}
          isFetching={loading}
          name={"Org List"}
          paging={paging}
          enableSearch={true}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
          enableFilters={false}
          onSearch={onSearch}
          sxTableProps={{
            body: {
              "&.MuiTableRow-root:hover": {
                backgroundColor: "#2196F30A",
              },
            },
          }}
        ></UTable>
      </Grid>
    </>
  )
}

export default OrganizationList
