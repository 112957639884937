import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { updaterxFormData } from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys } from "@/core/utils/formatters"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import UAlert from "@/ui-component/components/UAlert"
import { IAlertProps } from "@/ui-component/components/UAlert/UAlert"
import { setAlert } from "@/core/app/slices/alert/alertSlice"

const RenderSmartRxForm: FC<{
  formId: string
  setActionType?: (val: string) => void
  setShowRXForm?: (val: string | null) => void
  needToSubmit: boolean
  actionType?: string
  setSmartRxGeneratePDF?: (val: boolean) => void
}> = ({ formId, setActionType, setShowRXForm, needToSubmit, actionType, setSmartRxGeneratePDF }) => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { rxJsonForm } = useAppSelector((state: RootState) => state.formService)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [schema, setSchema] = useState({})
  const [uiSchema, setUiSchema] = useState({})
  const [currRxFormData, setCurrRxFormData] = useState<any>({})
  const [error, setError] = useState([])
  const { caseId, patientId } = useParams()
  const { isLoading } = useAppSelector((state: RootState) => state.formService)

  const initialAlertState: IAlertProps = {
    alertContent: "",
    showAlert: false,
    onClickHandler: () =>
      setAlertMeta({ ...initialAlertState, showAlert: false }),
    variant: "filled",
    severity: "info",
    verticalPosition: "top",
    horizontalPosition: "center",
    timeout: 5000,
  }
  const [alertMeta, setAlertMeta] = useState<IAlertProps>(initialAlertState)

  const fetchformData = () => {
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: formId,
        caseId: caseId,
        getSavedData: true,
        patientId: patientId,
        formType: "SMARTRX",
        uassistRound: 0,
      }),
    )
  }
  useEffect(() => {
    window.scrollTo({ top: 0 })
    setSchema({})
    setUiSchema({})
    if (rxJsonForm.id !== formId) {
      fetchformData()
    }
  }, [formId])

  useEffect(() => {
    onSaveRxForm()
  }, [isFormSubmitted])
  useEffect(() => {
    if (
      !checkIfObjectHasKeys(rxJsonForm.schema) ||
      !checkIfObjectHasKeys(rxJsonForm.uischema) ||
      !checkIfObjectHasKeys(rxJsonForm.data)
    )
      return

    const uiSchema = {
      ...rxJsonForm.uischema,
      elements: [...rxJsonForm.uischema.elements].filter(
        (element: any) => element.label !== "Additional Instructions",
      ),
    }
    setSchema({ ...rxJsonForm.schema })
    setUiSchema({ ...uiSchema })
    setCurrRxFormData({...rxJsonForm.data});
  }, [rxJsonForm.id])

  const handleDataChange = (data: any, errors: any) => {
    setCurrRxFormData(data);
    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  const onSaveRxForm = () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    } else if (isFormSubmitted) {
      dispatch(
        updateFormSavedData({
          formId: formId,
          caseId: caseId,
          payload: rxJsonForm.data,
          savedId: rxJsonForm.saveid,
          isRefinement: false,
        }),
      ).then(() => {
        if (isLoading === "succeeded") {
          fetchformData()
          dispatch(
            setAlert({
              message: t("uassist.uAssistForm.uassistDataSaveStatusSuccess"),
              timeout: 3000
            }),
          )
          setActionType && setActionType("edit")
          setShowRXForm && setShowRXForm(null)
          if(actionType === "edit"){
            setSmartRxGeneratePDF(true)
          }
        } else if (isLoading === "failed") {
          window.scrollTo({ top: 0 })

          if (setShowAlert) {
            setShowAlert({
              isShow: true,
              message: t("uassist.uAssistForm.uassitsDataSaveStatusFailure"),
              severity: "error",
            })
          } else {
            setAlertMeta({
              ...initialAlertState,
              showAlert: true,
              severity: "error",
              variant: "filled",
              alertContent: t(
                "uassist.uAssistForm.uassitsDataSaveStatusFailure",
              ),
            })
          }
        }
        setIsFormSubmitted(false)
      })
    }
  }

  return (
    <>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <UAlert
          alertProps={{
            zIndex: 10,
            position: "absolute",
            top: "0px !important",
            left: "50% !important",
            transform: "translateX(-50%)",
            width: "85%",
          }}
          alertContent={alertMeta.alertContent}
          showAlert={alertMeta.showAlert}
          onClickHandler={alertMeta.onClickHandler}
          variant={alertMeta.variant}
          severity={alertMeta.severity}
          verticalPosition={"top"}
          horizontalPosition={"center"}
          timeout={alertMeta.timeout}
        />
        {/* <UAlert
          alertContent={
            <p>kjsancdsnvcods</p>
          }
        ></UAlert> */}
        {needToSubmit && rxJsonForm.name && (
          <>
            <UText
              variant={"h4"}
              component={"div"}
              sxProp={{ textAlign: "center" }}
            >
              <Trans components={{ newLine: <br /> }}>
                {rxJsonForm.name +
                  " " +
                  t(
                    `uassist.uAssistForm.${
                      rxJsonForm.name !== "Limited Treatment"
                        ? "templateHeader"
                        : "limitedtreatmentHeader"
                    }`,
                  )}
              </Trans>
            </UText>
            <UText
              variant={"body2"}
              sxProp={{
                mt: 0,
                mb: 3,
                textAlign: "center",
                color: "rgb(0,0,0,0.6)",
              }}
            >
              <>
                {t("uassist.uAssistForm.formInfo")}
                <br />
                {rxJsonForm.name === "Limited Treatment"
                  ? t("uassist.uAssistForm.limitedInfo")
                  : ""}
              </>
            </UText>
          </>
        )}
        {!!(
          checkIfObjectHasKeys(schema) &&
          checkIfObjectHasKeys(uiSchema) &&
          checkIfObjectHasKeys(currRxFormData)
        ) && (
          <JsonFormBuilder
            schema={schema}
            uischema={uiSchema}
            defaultData={currRxFormData}
            handleChange={handleDataChange}
            isFormSubmitted={isFormSubmitted}
            setIsFormSubmitted={setIsFormSubmitted}
          />
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setActionType && setActionType("edit")
              setShowRXForm && setShowRXForm(null)
            }}
            sx={{ width: "96px", height: "36px" }}
          >
            <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
            {t("button.back")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              (!error.length) && dispatch(updaterxFormData(currRxFormData));
              setIsFormSubmitted(true);
            }}
            sx={{ width: "180px", height: "36px" }}
          >
            {t("button.saveCase")}
          </Button>
        </Box>
      </Grid>
    </>
  )
}

export default RenderSmartRxForm
