import { UButton, UModalPopup, UText } from "@/ui-component"
import { FC } from "react"

export const CaseUpdatedModal: FC<{
  showModal: boolean
  setShowModal: (val: boolean) => void
}> = ({ showModal, setShowModal }) => {

  return (
    <UModalPopup
      sxModalProps={{
        "#contentCntr": {
          padding: "24px",
        },
        "#btnListCntr": {
          padding: 3,
          gap: 1,
        },
        ".MuiDialog-paper": {
          maxWidth: 444,
          minHeight:"auto",
        },
      }}
      content={
        <UText variant={"body1"} sxProp={{ display: "block", height: "32px" }}>
          {
            "Please close, then reopen this case to sync the most recent changes."
          }
        </UText>
      }
      btnList={[
        <UButton
          key={"Case Updated Popup"}
          variant="contained"
          btnText={"Ok"}
          onClickHandler={() => {
            window.location.reload()
          }}
          sxProp={{ width: 53, height: 36 }}
        ></UButton>,
      ]}
      isAlert={showModal}
    />
  )
}
