import Block from '@/components/Block/Block'
import { useAppDispatch, useAppSelector } from '@/core/app/hooks'
import { downloadUassistSummaryPdf } from '@/core/app/slices/order/orderThunkApi'
import { UButton, UText } from '@/ui-component/components'
import { LandingLayoutProps } from '@/ui-component/theme/uCommon.theme'
import { Box, CircularProgress, Grid } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import logo from "@/assets/images/ulablogo.svg"
import { canAll } from '@/core/utils/access/access'
import { RootState } from '@/core/app/store'
import { UI_PERMISSION } from '@/core/utils/access/permissionConstants'
import { useTranslation } from 'react-i18next'

const UassistSummary: FC = () => {
  const { orgId, orderId } = useParams()
  const dispatch = useAppDispatch()
  const [countdown, setCountdown] = useState<number | null>(null)
  const { user } = useAppSelector((state: RootState) => state.userService)
  const isAuthorized = canAll(user?.app_permissions || [])([UI_PERMISSION.GLOBAL_VIEWALLORGS])
  const { uassistSummaryFile: { status, message } } = useAppSelector((state) => state.orderService)
  const { t } = useTranslation("common")
  

  const statusMessages = useMemo(() => {
    let errorMessage = null;
    if (status === 'error' && message.status === 404) {
      errorMessage = t("downloadFileFromSFDC.statusMessages.error.fileNotFound")
    }
    return {
      success: {
        title: t("downloadFileFromSFDC.statusMessages.success.title"),
        message: t("downloadFileFromSFDC.statusMessages.success.message"),
      },
      loading: {
        title: t("downloadFileFromSFDC.statusMessages.loading.title"),
        message: t("downloadFileFromSFDC.statusMessages.loading.message"),
      },
      error: {
        title: t("downloadFileFromSFDC.statusMessages.error.title"),
        message: errorMessage ?? t("downloadFileFromSFDC.statusMessages.error.message")
      }
    }
  }, [status, message])

  useEffect(() => {
    const handleDownload = async () => {
      await dispatch(downloadUassistSummaryPdf({ orgId, orderId })).unwrap()
    }
    if (isAuthorized) {
      handleDownload()
    }
  }, [dispatch, isAuthorized, orgId, orderId])

  useEffect(() => {
    if (status === 'success') {
      startCountdown()
    }
  }, [status])

  const startCountdown = () => {
    const timeout = 3
    setCountdown(timeout)
    const timer = setInterval(() => {
      setCountdown((prev) => (prev ? prev - 1 : -1))
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
      window.close()
    }, (timeout + 1) * 1000)
  }

  return (
    <Grid
      container
      sx={{ width: "100%", height: "100%", background: LandingLayoutProps.background }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Block
        component={"div"}
        sxProp={{
          minWidth: "552px",
        }}>
        <Box
          component={"img"}
          src={logo}
          alt={"Logo"}
          loading="lazy"
          sx={{ mb: 3 }}
        />
        {isAuthorized ? (
          <>
            {statusMessages[status] && (
              <>
                <UText variant={"h4"} color={"text"} sxProp={{ textAlign: "center", mb: 2 }}>
                  {statusMessages[status].title}
                  {status === 'loading' && <CircularProgress size="30px" sx={{ ml: 1 }} />}

                </UText>
                <UText variant={"body1"} color={"text"} sxProp={{ textAlign: "center", mb: 6 }}>
                  {statusMessages[status].message}
                </UText>
              </>
            )}
            {countdown !== null && countdown >= 0 && (
              <UText variant="body2" color="#666">
                {t("downloadFileFromSFDC.closeTab.countdownText1")}{" "}{countdown}{" "}{t("downloadFileFromSFDC.closeTab.countdownText2")}
              </UText>
            )}
            {countdown === -1 && (
              <UText variant="body2" color="#666">{t("downloadFileFromSFDC.closeTab.closeTabManually")}</UText>
            )}
            {status === "error" && (
              <UButton variant="contained" btnText={t("button.close")} sxProp={{ width: "100%" }} onClickHandler={() => {
                window.close()
              }} />
            )}
          </>
        ) : (
          <>
            <UText variant={"h4"} color={"text"} sxProp={{ textAlign: "center", mb: 2 }}>
              {t("downloadFileFromSFDC.accessRestricted.title")}
            </UText>
            <UText variant={"body1"} color={"text"} sxProp={{ textAlign: "center", mb: 1 }}>
              {t("downloadFileFromSFDC.accessRestricted.body1")}
            </UText>
            <UText variant={"body1"} color={"text"} sxProp={{ textAlign: "center", mb: 6 }}>
              {t("downloadFileFromSFDC.accessRestricted.body2")}
            </UText>
            <UButton variant="contained" btnText={t("button.close")} sxProp={{ width: "100%" }} onClickHandler={() => {
              window.close()
            }} />
          </>
        )}
      </Block>
    </Grid>
  )
}

export default UassistSummary