import FullscreenIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import { useOktaAuth } from "@okta/okta-react"
import { FC, memo, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"

import { caseManagement, wasmModule } from "@/gluelayer"

import faq from "../../../assets/images/faq.svg"
import profileunfilled from "../../../assets/images/profile-unfilled.svg"
import profile from "../../../assets/images/profile.svg"
import logo from "../../../assets/images/uLab-Logo.svg"
import { UButton, UText } from "../../../components"
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { fetchDoctorsList } from "../../../core/app/slices/doctors/doctorThunkApi"
import {
  fetchUserPreferences,
  getCreditCardDetails,
  getSystemMessage,
  updateUserPreferences,
} from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import usePermissions from "../../../hooks/userPermission"
import {
  QUERY_PARAMS_STORAGE_KEY,
  QUERY_PARAMS_STORAGE_KEY_ORDER,
} from "../../../modules/Patient/PatientList/patientTable/table.config"
import { CreditCardModal } from "../../../modules/Settings/UserProfile/CreditCardModal"
import { setWeSmileUI } from "../../../wasm3d/Wasm3DServiceSlice"
import Shortcuts from "@/modules/Help/help/Shortcuts"
import ProfileOptions from "./ProfileOptions"
import { fetchFormLists } from "@/core/app/slices/uassist/formThunkApi"

const HeaderLogo: FC<{ homePageRedirect: () => void }> = memo(
  ({ homePageRedirect }) => {
    return (
      <Box
        component={"img"}
        src={logo}
        onClick={homePageRedirect}
        alt={"Logo"}
        loading="lazy"
        sx={{ cursor: "pointer" }}
      />
    )
  },
)

HeaderLogo.displayName = "HeaderLogo"

const Header: FC = () => {
  const { hasAny, doesNotHaveAny, doesNotHaveAll } = usePermissions()
  const navigate = useMyNavigation()
  const location = useLocation()
  const refElement = useRef(null)
  const dispatch = useAppDispatch()

  const { t } = useTranslation("common")
  const { oktaAuth, authState } = useOktaAuth()
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const logOutRedirect = async () => oktaAuth.signOut()
  const { user } = useAppSelector((state: RootState) => state.userService)

  const { switchViewEdit } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { preferences, isAllowToViewAllOrg, current_orgId } = useAppSelector(
    (state: RootState) => state.userService.user,
  )

  const [showShortcutPopup, setShowShortcutPopup] = useState(false)

  const isTreatPage = location.pathname.includes("/treat")

  useEffect(() => {
    //Remove filter from local storge on page refresh
    localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY)
    localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY_ORDER)
    dispatch(fetchDoctorsList())
    dispatch(fetchUserPreferences())
    dispatch(getCreditCardDetails())
    dispatch(getSystemMessage())
    dispatch(fetchFormLists())
  }, [dispatch, user.current_orgId])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) homePageRedirect()
  }

  const homePageRedirect = () => {
    navigate(
      isAllowToViewAllOrg ? `/org/${current_orgId}/patients` : "/patients",
    )
  }
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const { status: creditCardStatus } = useAppSelector(
    (state: RootState) => state.userService?.paymentInfo,
  )

  const menuItems = [
    {
      name: "Settings",
      id: "SETTINGS",
      hideMenu: isAllowToViewAllOrg,
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/settings/")
      },
    },
    {
      name: "uLab Account",
      id: "ULAB_ACCOUNT",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/uLabAccount")
      },
    },
    {
      name: "Custom Packaging",
      id: "CUSTOM_PACKAGING",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/customPackaging/")
      },
    },
    {
      name: "uDesign App",
      id: "UDESIGN_APP",
      hideMenu: isAllowToViewAllOrg,
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/UDesignApp")
      },
    },
    {
      name: "Logout",
      hideMenu: isAllowToViewAllOrg,
      onClick: logOutRedirect,
      sx: {
        borderTop: 1,
        borderColor: "divider",
      },
    },
    // {
    //   name: "Show/Hide weSmile",
    //   hideMenu: isAllowToViewAllOrg,
    //   onClick: () => dispatch(setWeSmileUI(!weSmileUI)),
    // },
  ]

  const helpMenuItems = [
    {
      name: t("header.helpMenu.help"),
      onClick: () => {
        setIsHelpOpen(false)
        window.open("/help/help")
      },
    },
    {
      name: t("header.helpMenu.shortcuts"),
      onClick: () => {
        setIsHelpOpen(false)
        setShowShortcutPopup(true)
      },
    },
    {
      name: t("header.helpMenu.releaseNotes"),
      onClick: () => {
        window.open("/help/releaseNotes")
        setIsHelpOpen(false)
      },
    },
  ]

  const onClickHandler = () => {
    navigate("/")
  }

  const [openSkipTooltip, setOpenSkipTooltip] = useState<boolean>(false)

  useEffect(() => {
    if (preferences) {
      setOpenSkipTooltip(
        !preferences?.on_show_setting_tooltip &&
          preferences?.on_show_welcome_modal,
      )
    }
  }, [preferences])

  const handleMenuClose = () => {
    setAnchorEl(null)
    setIsProfileOpen(false)
    setIsHelpOpen(false)
  }

  const updateMenuByPermissions = (actions: any) => {
    if (doesNotHaveAny(UI_PERMISSION.UI_CUSTOMBOX_VIEW)) {
      actions = actions.filter((item) => item.id !== "CUSTOM_PACKAGING")
    }
    if (doesNotHaveAny(UI_PERMISSION.UI_UDESIGNAPP_DOWNLOAD)) {
      actions = actions.filter((item) => item.id !== "UDESIGN_APP")
    }

    if (doesNotHaveAny(UI_PERMISSION.UI_ACCOUNT_VIEW)) {
      actions = actions.filter((item) => item.id !== "ULAB_ACCOUNT")
    }
    if (doesNotHaveAny(UI_PERMISSION.UI_PATIENTS_UNARCHIVE)) {
      actions = actions.filter(
        (item) => item.id !== "OPEN_UNARCHIVE_PATIENT_MODEL",
      )
    }

    if (
      doesNotHaveAll([
        UI_PERMISSION.UI_PROFILE_VIEW,
        UI_PERMISSION.UI_CLINICALSETTING_VIEW,
        UI_PERMISSION.UI_SMARTRXTEMPLATE_VIEW,
      ])
    ) {
      actions = actions.filter((item) => item.id !== "SETTINGS")
    }

    return actions
  }

  // Listen for fullscreen change events
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreen = !!(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      )
      setFull(isFullscreen)
    }

    // Add event listeners
    document.addEventListener("fullscreenchange", handleFullscreenChange)
    document.addEventListener("mozfullscreenchange", handleFullscreenChange)
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange)
    document.addEventListener("MSFullscreenChange", handleFullscreenChange)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange)
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      )
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      )
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange)
    }
  }, [])

  // full-screen
  const [full, setFull] = useState<boolean>(false)
  const clickFull = () => {
    if (!full) {
      const docElm = document.documentElement
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } /*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } /*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } /*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    setFull(!full)
  }

  return (
    <>
      {hasAny(UI_PERMISSION.UI_PROFILE_UPDATEPAYMENT) &&
        !isAllowToViewAllOrg &&
        creditCardStatus &&
        creditCardStatus !== "ACTIVE" &&
        creditCardStatus !== "NO_CC_PROMPT" && <CreditCardModal />}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: "#ffffff !important",
          boxShadow: "0px 0px 0px 1px #E0E0E0",
          height: "63px",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <HeaderLogo homePageRedirect={homePageRedirect} />

          <Box
            component="div"
            sx={{
              borderLeft: "1px solid #000000",
              opacity: "0.12",
              height: "35px",
              mx: 2,
            }}
          ></Box>
          <UText
            variant="h6"
            color="#757575"
            sxProp={{ mr: 2 }}
            onClick={onClickHandler}
          >
            {t("header.company")}
          </UText>
          <Box sx={{ flexGrow: 1, ml: 4 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
                <Tabs
                  value={
                    [
                      "/patients",
                      `/org/${current_orgId}/patients`,
                      "/billing",
                      `/org/${current_orgId}/billing`,
                      "/orders",
                      `/org/${current_orgId}/orders`,
                    ].includes(location.pathname)
                      ? location.pathname
                      : false
                  }
                  onChange={handleChange}
                  aria-label="tabs"
                >
                  {!isTreatPage && (
                    <Tab
                      label={t("header.patientTabtitle")}
                      value={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/patients`
                          : "/patients"
                      }
                      component={Link}
                      to={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/patients`
                          : "/patients"
                      }
                      sx={{
                        height: "64px",
                        letterSpacing: "0.4px",
                        mr: "16px",
                      }}
                    />
                  )}
                  {hasAny(UI_PERMISSION.UI_ORDERS_VIEW) && !isTreatPage && (
                    <Tab
                      label={t("header.orderTabtitle")}
                      value={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/orders`
                          : "/orders"
                      }
                      component={Link}
                      to={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/orders`
                          : "/orders"
                      }
                      sx={{
                        height: "64px",
                        letterSpacing: "0.4px",
                        mr: "16px",
                      }}
                    />
                  )}
                  {hasAny(UI_PERMISSION.UI_BILLING_VIEW) && !isTreatPage && (
                    <Tab
                      label={t("header.billing")}
                      value={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/billing`
                          : "/billing"
                      }
                      component={Link}
                      to={
                        isAllowToViewAllOrg
                          ? `/org/${current_orgId}/billing`
                          : "/billing"
                      }
                      sx={{
                        height: "64px",
                        letterSpacing: "0.4px",
                        mr: "16px",
                      }}
                    />
                  )}
                </Tabs>
              </Box>
            </Box>
          </Box>
          {!full ? (
            <FullscreenIcon
              onClick={() => {
                clickFull()
              }}
              sx={{
                color: "#757575",
                mr: 2,
                cursor: "pointer",
              }}
            />
          ) : (
            <FullscreenExitIcon
              onClick={() => {
                clickFull()
              }}
              sx={{
                color: "#757575",
                mr: 2,
                cursor: "pointer",
              }}
            />
          )}
          <Box
            data-testid={"faqIcon"}
            component={"img"}
            src={faq}
            alt={"Logo"}
            onClick={(e) => {
              setIsHelpOpen(!isHelpOpen)
              setAnchorEl(e.currentTarget)
            }}
            loading="lazy"
            sx={{ cursor: "pointer", mr: 2 }}
          />
          <Box component={"div"} ref={refElement} sx={{ display: "flex" }}>
            <Tooltip
              open={openSkipTooltip}
              arrow
              placement={"bottom-start"}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [10, 0],
                    },
                  },
                ],
              }}
              slotProps={{
                tooltip: {
                  style: {
                    padding: 14,
                    backgroundColor: "#17AADD",
                    maxWidth: "282px",
                    borderRadius: "16px",
                    marginTop: "8px",
                    boxShadow:
                      "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  },
                },
                arrow: {
                  style: {
                    color: "#17AADD",
                    width: "24px",
                    height: "17px",
                    marginTop: "-1em",
                  },
                },
              }}
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <UText
                    variant={"subtitle1"}
                    sxProp={{
                      fontWeight: 500,
                      lineHeight: "131.25%",
                      letterSpacing: "normal",
                    }}
                  >
                    <Trans
                      components={{
                        bold: <b />,
                      }}
                    >
                      {"header.skipTooltip.content"}
                    </Trans>
                  </UText>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <UButton
                      sxProp={{
                        boxShadow:
                          "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                        fontSize: "13px",
                        lineHeight: "169.231%",
                        letterSpacing: "0.46px",
                        minHeight: "30px",
                        height: "auto",
                        padding: "4px 10px",
                        backgroundColor: "#E0E0E0",
                        color: "black",
                      }}
                      size={"small"}
                      variant={"shade"}
                      onClickHandler={() => {
                        dispatch(
                          updateUserPreferences({
                            on_show_setting_tooltip: true,
                          }),
                        )
                      }}
                      btnText={t("header.skipTooltip.gotItBtn")}
                    />
                  </Box>
                </Box>
              }
            >
              <Box
                component={"img"}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                  setIsProfileOpen(!isProfileOpen)
                }}
                src={isProfileOpen ? profile : profileunfilled}
                alt={"userLogo"}
                sx={{ cursor: "pointer", padding: !isProfileOpen ? "4px" : "" }}
              />
            </Tooltip>
            <ProfileOptions
              menuItems={updateMenuByPermissions(menuItems)}
              anchorEl={anchorEl}
              isOpen={isProfileOpen}
              handleOnClose={handleMenuClose}
              menuProps={{
                ".MuiPaper-root": {
                  minWidth: "200px",
                },
              }}
            />
            <ProfileOptions
              handleOnClose={handleMenuClose}
              menuProps={{
                ".MuiPaper-root": {
                  minWidth: "220px",
                },
              }}
              menuItems={helpMenuItems}
              anchorEl={anchorEl}
              isOpen={isHelpOpen}
            />
          </Box>
        </Toolbar>
        <Shortcuts isOpen={showShortcutPopup} onClose={()=>{setShowShortcutPopup(false)}} />
      </AppBar>
    </>
  )
}

export default Header
