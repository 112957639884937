import { UButton, UModalPopup, UText } from "@/components"
import { FC } from "react"
import { useTranslation } from "react-i18next"

export const TabExceededPopup: FC<{
  setOpenTabExceededModal: (val: boolean) => void
  openTabExceededModal: boolean
}> = ({ openTabExceededModal, setOpenTabExceededModal }) => {
  const { t } = useTranslation("common")

  return (
    <UModalPopup
      sxModalProps={{
        "#contentCntr": {
          padding: "24px",
        },
        "#btnListCntr": {
          padding: 3,
          gap: 1,
        },
        ".MuiDialog-paper": {
          maxWidth: 444,
          minHeight: "auto",
        },
      }}
      content={
        <UText variant={"body1"} sxProp={{ display: "block", height: "32px" }}>
          {t("tabExceededPopup.body")}
        </UText>
      }
      btnList={[
        <UButton
          key={"Tab exceeded popup"}
          variant="contained"
          btnText={t("tabExceededPopup.btn")}
          onClickHandler={() => {
            setOpenTabExceededModal(false)
          }}
          sxProp={{ width: 53, height: 36 }}
        ></UButton>,
      ]}
      isAlert={openTabExceededModal}
    />
  )
}
