import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { caseManagement, saveArchType } from "@/gluelayer"

import { UButton, UModalPopup, UText } from "../../../components"
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { postMessageData } from "../../../core/app/slices/banners/bannerThunkApi"
import { getCaseById } from "../../../core/app/slices/case"
import { getCaseByPatient, updateCaseById } from "../../../core/app/slices/case/caseThunkApi"
import { setIsScansRendered } from "../../../core/app/slices/clinical/clinicalSlice"
import { uploadCaseZips } from "../../../core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { resetPhotographs } from "../../../core/app/slices/records/photograph/photographSlice"
import { resetPrescriptionSchema } from "../../../core/app/slices/uassist/formSlice"
import { fetchClinicalSettings } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import Photograph from "../../Records/Photograph/Photograph"
import { IRecordRoutesProps } from "../../Records/Records.module"
import Scans from "../../Records/Scans/Scans"
import Xrays from "../../Records/Xrays/Xray"
import { getAttentionBannerButton } from "../Overview/banner"

import SmartRxFormBanner from "./components/SmartRxFormBanner"
import RxForm from "./rxForm"
import SummaryView from "./SummaryView"
import NavigationBlockPopup from "@/components/NavigationBlockPopup/NavigationBlockPopup"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { CASE_STATUS } from "../config/status.config"

const RecordRoutes: FC<IRecordRoutesProps> = ({
  showError,
  xrayErrors,
  scanErrors,
  photographErrors,
  setScanErrors,
  setPhotographErrors,
  setXrayErrors,
  flagNextBtn,
  steps,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
  handleLowerStl,
  handleUpperStl,
  handleShowStl,
  postMessageUpdate,
  postSettingTozips,
  bannerData,
  handleNext,
  handleBack,
}) => {
  const { t } = useTranslation("common")

  return (
    <Routes>
      <Route
        path={"scans"}
        element={
          <Scans
            setScanErrors={setScanErrors}
            flagClickNext={flagNextBtn}
            steps={steps}
            handleLowerStl={handleLowerStl}
            handleUpperStl={handleUpperStl}
            handleShowStl={handleShowStl}
            handleNext={handleNext}
            caseType={"hold_scan"}
          />
        }
      />
      <Route
        path={"photograph"}
        element={
          <Photograph
            title={t("records.photograph.title")}
            description={t("records.refinement.photograph.description")}
            setPhotographErrors={setPhotographErrors}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            setIsDragOpen={setIsDragOpen}
            isDragOpen={isDragOpen}
            postMessageUpdate={postMessageUpdate}
          />
        }
      />
      <Route
        path={"xray"}
        element={
          <Xrays
            title={t("records.xray.title")}
            description={t("records.refinement.xray.description")}
            showError={showError}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            xrayErrors={xrayErrors}
            setXrayErrors={setXrayErrors}
          />
        }
      />
      <Route
        path={"RxForm"}
        element={
          <RxForm
            postSettingTozips={postSettingTozips}
            handleBack={handleBack}
            isPhotoAndRxForm={
              getAttentionBannerButton(bannerData?.warning_subject)?.message
            }
          />
        }
      />
      <Route
        path={"SmartRxFormBanner/:formName/:formid"}
        element={<SmartRxFormBanner />}
      />
      <Route path={"SummaryView"} element={<SummaryView />} />
    </Routes>
  )
}

const AttentionBanner: FC = () => {
  const [scanErrors, setScanErrors] = useState<boolean>(false)
  const [photographErrors, setPhotographErrors] = useState<boolean>(false)
  const [xrayErrors, setXrayErrors] = useState<boolean>(false)
  const [isDragEmpty, setIsDragEmpty] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [flagNextBtn, setFlagNextBtn] = useState<boolean>(false)
  const [isDragOpen, setIsDragOpen] = useState<boolean>(false)
  const [isNextButton, setIsNextButton] = useState<boolean>(false)
  const [isBackButton, setIsBackButton] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<any>(0)
  const [individualPhoto, setIndividualPhoto] = useState<any>(0)
  const [compositePhotos, setCompositePhotos] = useState<any>(0)
  const [panoXray, setPanoXray] = useState<any>(0)
  const [lowerStl, setLowerStl] = useState<string>("")
  const [upperStl, setUpperStl] = useState<string>("")
  const [showStl, setShowStl] = useState({ isupper: false, islower: false })
  const [nextPopup, setNextPopup] = useState<boolean>(false)
  const nextNavigateRef = useRef(false)
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const location = useLocation()
  const { t } = useTranslation("common")
  const showError = false

  const { isScansRendered } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const bannerRoute = useAppSelector(
    (state: RootState) => state.bannerService.bannerArray,
  )
  const { compositePhoto, individualPhotos } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const { bannerData } = useAppSelector(
    (state: RootState) => state.bannerService,
  )
  const { preferences } = useAppSelector(
    (state: RootState) => state.userService?.user,
  )

  const { pano } = useAppSelector((state: RootState) => state.xrayService)

  const { clinicalSettings, clinicalSettingsFetchStatus } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )

  const { rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  
  const { isRouteChangeBlocked } = useAppSelector((state: RootState) => state.navigationPopup)
  
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  useEffect(() => {
    dispatch(fetchClinicalSettings())
  }, [dispatch])

  const postSettingTozips = () => {
    if (!clinicalSettingsFetchStatus) return
       const archesToTreat = rxPrescription.data.arches_to_treat as {
          upper: any
          lower: any
        }
        console.log("🚀 ~ .then ~ archesToTreat:", archesToTreat)
    
        caseManagement
          .saveJsonDataToZip(
            JSON.stringify(clinicalSettings),
            "uassist_team_settings.json",
            "Setting Data2",
          )
          .then(() => {
            if (caseDetail) {
              const udesign_json = JSON.parse(caseDetail.udesign_json)
              const newUdesignJsonObj = {
                ...udesign_json,
                UpperType: archesToTreat.upper
                  ? archesToTreat.upper.aligner_type === "Aligner"
                    ? "A"
                    : "R"
                  : "",
                LowerType: archesToTreat.lower
                  ? archesToTreat.lower.aligner_type === "Aligner"
                    ? "A"
                    : "R"
                  : "",
              }
              console.log(
                "🚀 ~ caseManagement.getCaseFiles ~ newUdesignJsonObj:",
                newUdesignJsonObj,
              )
    
              dispatch(
                updateCaseById({
                  patientId,
                  caseId,
                  payload: {
                    case_extra_attrs: JSON.stringify(newUdesignJsonObj),
                  },
                }),
              )
    
              saveArchType(
                archesToTreat.upper
                  ? archesToTreat.upper.aligner_type === "Aligner"
                    ? 0
                    : 1
                  : null,
                archesToTreat.lower
                  ? archesToTreat.lower.aligner_type === "Aligner"
                    ? 0
                    : 1
                  : null,
              )
            }
          })
          .then(() => {
            caseManagement.getCaseFiles(["bulk2"]).then((r) => {
              const zips = []
              if (r) {
                for (const k in r) {
                  zips.push({
                    fileName: k,
                    file: r[k],
                  })
                }
                dispatch(
                  uploadCaseZips({
                    zips,
                    patientId,
                    caseId,
                  }),
                )
              }
            })
          })
  }

  useEffect(() => {
    if (location?.pathname.includes("/photograph")) {
      setIndividualPhoto(individualPhotos.length)
      setCompositePhotos(compositePhoto.length)
    } else if (location?.pathname.includes("/xray")) {
      setPanoXray(pano.length)
    } else if (location?.pathname.includes("/scans")) {
      setLowerStl(lowerStl || "")
      setUpperStl(upperStl || "")
    }
    isDisableNextButton()
  }, [
    individualPhoto,
    location.pathname,
    individualPhotos,
    compositePhotos,
    compositePhoto,
    panoXray,
    pano,
  ])

  useEffect(() => {
    //reset all file redux state  when redring this route
    dispatch(resetPhotographs())
    dispatch(getCaseById({ patientId, caseId }))
    if (location?.pathname.includes("/RxForm")) {
      dispatch(resetPrescriptionSchema())
    }
  }, [])

  // useEffect(() => {
  //   if (location?.pathname.includes("/scans")) {
  //     if (lowerStl === "stl" || upperStl === "stl") {
  //       setFlagNextBtn(!flagNextBtn)
  //     }
  //   }
  // }, [lowerStl, upperStl])

  const isDisableNextButton = () => {
    if (location?.pathname.includes("/photograph")) {
      return (
        !photoTypeJson ||
        (photoTypeJson && Object.keys(photoTypeJson).length === 0)
      )
    } else if (location?.pathname.includes("/xray")) {
      return panoXray === 0
    } else if (location?.pathname.includes("/scans")) {
      if (showStl.isupper && showStl.islower) {
        if (lowerStl === "stl" || upperStl === "stl") {
          return false
        } else {
          return true
        }
      } else if (lowerStl === "stl" || upperStl === "stl") {
        return false
      } else {
        return true
      }
    }
  }

  const postMessageUpdate = () => {
    dispatch(
      postMessageData({
        patientId,
        caseId: caseId.toString(),
        payload: {
          message: getAttentionBannerButton(bannerData?.warning_subject)
            .message,
        },
      }),
    ).then((res) => {
      if (res && res.payload && res.payload.status === "Success") {
        navigate(`/overview/patient/${patientId}`)
        dispatch(getCaseByPatient({ patientId }))
      }
    })
  }

  const handleUpdateButton = async () => {
    await dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))

    if (
      location?.pathname.includes("/scans") &&
      showStl.isupper &&
      showStl.islower &&
      ((lowerStl === "stl" && upperStl !== "stl") ||
        (lowerStl !== "stl" && upperStl === "stl")) &&
      !nextNavigateRef.current
    ) {
      setNextPopup(true)
    } else {
      if (
        isDragEmpty &&
        isDragOpen &&
        preferences &&
        !preferences?.on_show_sort_warning
      ) {
        setIsAlertOpen(true)
        return
      }
      if (location?.pathname.includes("/scans")) setFlagNextBtn(true)
      nextNavigateRef.current = false
      setNextPopup(false)
      postMessageUpdate()
    }
  }

  useEffect(() => {
    localStorage.setItem("lastRoute", location.pathname)
    const lastRoute = localStorage.getItem("lastRoute")
    const currentRoute = lastRoute.split("attentionBanner/")
    if (currentRoute[1]) {
      const lastElement = bannerRoute[bannerRoute.length - 1]
      if (currentRoute[1] == lastElement) {
        setIsNextButton(false)
      } else {
        setIsNextButton(true)
      }
      const index = bannerRoute.indexOf(currentRoute[1])
      setCurrentIndex(index)
      if (index > 0) setIsBackButton(true)
      
      if ( 
        index===0 
        && caseDetail?.caseDisposition === CASE_STATUS.ATTENTION_NEEDED 
        && location?.pathname.includes("/photograph") // bannerRoute[index]
      ){
        setIsBackButton(true)
      }

    } else {
      navigate(bannerRoute[currentIndex], {replace:true})
      if (bannerRoute.length > 1) setIsNextButton(true)
      setIsBackButton(false)
    }
    if (isScansRendered) return
    if (location.pathname.includes("/scans")) {
      dispatch(setIsScansRendered(true))
    } else if (!isScansRendered && bannerRoute.includes("scans")) {
      navigate(
        `/overview/patient/${patientId}/case/${caseId}/attentionBanner/scans`,
      )
    }
  }, [bannerRoute, location.pathname, caseDetail?.caseDisposition])

  const handleNextButton = async () => {
    await dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    if (
      location?.pathname.includes("/scans") &&
      showStl.isupper &&
      showStl.islower &&
      ((lowerStl === "stl" && upperStl !== "stl") ||
        (lowerStl !== "stl" && upperStl === "stl")) &&
      !nextNavigateRef.current
    ) {
      setNextPopup(true)
    } else {
      const index = currentIndex + 1
      if (index < bannerRoute.length) {
        if (index === bannerRoute.length - 1) setIsNextButton(false)
        setNextPopup(false)
        nextNavigateRef.current = false
        if (location?.pathname.includes("/scans")) {
          setFlagNextBtn(true)
        } else {
          navigate(bannerRoute[index])
          setCurrentIndex(index)
          setIsBackButton(true)
        }
      }
    }
  }

  const handleBackButton = () => {
    const index = currentIndex - 1
    if (index >= 0) {
      if (index === 0) {
        if ( caseDetail?.caseDisposition === CASE_STATUS.ATTENTION_NEEDED && location?.pathname.includes("/photograph") ){
          navigate(`/overview/patient/${patientId}`)
        }
        setFlagNextBtn(false)
        setIsBackButton(false)
      }
      if (index !== bannerRoute.length - 1) setIsNextButton(true)

      bannerRoute[index] === "scans"
        ? navigate(bannerRoute[index], {
            state: { isBackFromAttentionBanner: true },
          })
        : navigate(bannerRoute[index], {
            state: { isBackFromAttentionBanner: false },
          })
      if(!isRouteChangeBlocked)
        setCurrentIndex(index)
    }else{
      navigate(`/overview/patient/${patientId}`)
    }
  }

  const handleLowerStl = (value) => {
    setLowerStl(value)
  }

  const handleUpperStl = (value) => {
    setUpperStl(value)
  }

  const handleShowStl = (value) => {
    setShowStl(value)
  }

  return (
    <>
      <Container component="main" maxWidth={"lg"} sx={{ mb: 4, padding:"0px !important" }}>
        <Paper
          elevation={0}
          sx={{
            my: { xs: 3, md: 6 },
            p: "40px",
            borderRadius: "16px",
            marginTop: "10px !important",
            margin: "0 auto",
          }}
        >
          <RecordRoutes
            xrayErrors={xrayErrors}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            showError={showError}
            setScanErrors={setScanErrors}
            setPhotographErrors={setPhotographErrors}
            setXrayErrors={setXrayErrors}
            flagNextBtn={flagNextBtn}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            isDragOpen={isDragOpen}
            setIsDragOpen={setIsDragOpen}
            handleLowerStl={handleLowerStl}
            handleUpperStl={handleUpperStl}
            handleShowStl={handleShowStl}
            postMessageUpdate={postMessageUpdate}
            postSettingTozips={postSettingTozips}
            bannerData={bannerData}
            handleBack={handleBackButton}
            handleNext={() => {
              if (!bannerRoute[currentIndex + 1]) return
              navigate(bannerRoute[currentIndex + 1])
              setCurrentIndex(currentIndex + 1)
              setIsBackButton(true)
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 1,
            }}
          >
            {isBackButton &&
              !location?.pathname.includes("/SummaryView") &&
              !location?.pathname.includes("/RxForm") && (
                <Button
                  variant="outlined"
                  onClick={handleBackButton}
                  sx={{ width: "96px", height: "36px" }}
                >
                  <ChevronLeftIcon
                    fontSize={"small"}
                    sx={{ marginRight: "3px" }}
                  />
                  {t("button.back")}
                </Button>
              )}
            <Box sx={{ flex: "1 1 auto" }} />
            {isNextButton &&
              !location?.pathname.includes("/RxForm") &&
              !location?.pathname.includes("/SummaryView") &&
              !location?.pathname.includes(
                ROUTE_ATTENTION_BANNER.smartRxFormBanner,
              ) && (
                <Button
                  variant="contained"
                  sx={{ width: "92px", height: "36px" }}
                  disabled={isDisableNextButton()}
                  onClick={handleNextButton}
                >
                  {t("button.next")}
                  <ChevronRightIcon
                    sx={{ marginLeft: "3px" }}
                    fontSize={"small"}
                  />
                </Button>
              )}
            {!isNextButton && !location?.pathname.includes("/SummaryView") && (
              <Button
                variant="contained"
                sx={{ width: "111px", height: "36px" }}
                disabled={isDisableNextButton()}
                onClick={handleUpdateButton}
              >
                {t("button.update")}
                <ChevronRightIcon
                  sx={{ marginLeft: "3px" }}
                  fontSize={"small"}
                />
              </Button>
            )}
          </Box>
        </Paper>
      </Container>
      <UModalPopup
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
            // height: 182,
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        title={<UText variant={"h6"}>{t("records.scans.nextPopupTile")}</UText>}
        content={
          <Grid container flexDirection={"column"}>
            <UText variant={"body1"}>
              {t("records.scans.nextPopup1")}
              <br />
              <br />
              {t("records.scans.nextPopup2")}
            </UText>
          </Grid>
        }
        isAlert={nextPopup}
        btnList={[
          <UButton
            variant={"contained"}
            btnText={"YES"}
            key={"yes"}
            sxProp={{ minWidth: 58, height: 36 }}
            onClickHandler={() => {
              nextNavigateRef.current = true
              isNextButton ? handleNextButton() : handleUpdateButton()
            }}
          />,
          <UButton
            variant={"shade"}
            btnText={"NO"}
            key={"no"}
            onClickHandler={() => {
              nextNavigateRef.current = false
              setNextPopup(false)
            }}
            sxProp={{ minWidth: 54, height: 36 }}
          />,
        ]}
      />
      
      <NavigationBlockPopup
        contentText={t("popup.clickOnUpdateToSave")}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default AttentionBanner
