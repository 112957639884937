import { createContext, useContext, useRef } from "react"

// Create a context with an empty default value
const TabContext = createContext(null)

// Provider component to wrap around AppRoutes
export const TabProvider = ({ children }) => {
  const tabRefs = useRef({}) // This ref is shared across the app

  return <TabContext.Provider value={tabRefs}>{children}</TabContext.Provider>
}

// Custom hook to use the TabContext
export const useTabRef = () => {
  return useContext(TabContext)
}
