import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { isTokenValid } from "@/core/config/okta.config"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { ULoading } from "@/ui-component"
import { useOktaAuth } from "@okta/okta-react"
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

export const SessionOnlyRoute = () => {
  const navigate = useMyNavigation()
  const isLogin = isTokenValid()
  const location = useLocation()
  const { user } = useAppSelector((state: RootState) => state.userService)
  const { authState } = useOktaAuth()

  useEffect(() => {
    const destinationPath = `${location.pathname + location.search + location.hash}`

    if (!isLogin) {
      navigate(
        `/auth/login?redirectTo=${encodeURIComponent(destinationPath)}`,
        {
          replace: true,
        },
      )
    }

  }, [location, user])

  if (!authState || !authState?.isAuthenticated) {
    return <ULoading isLoading={true} />
  }
  return (
    <>
      <Outlet />
    </>
  )
}