import { convertSummaryValue } from "../../core/utils/formatters"

const generateSummaryData = (defaultdata: any) => {
  if (
    defaultdata.arches_to_treat &&
    defaultdata.arches_to_treat.lower &&
    defaultdata.arches_to_treat.upper
  ) {
    const lowerArches = defaultdata.arches_to_treat.lower
    const upperArches = defaultdata.arches_to_treat.upper
    const upperData = `${upperArches.isChecked ? upperArches.isChecked : ""} ${
      upperArches.aligner_type ? ":" : ""
    } ${upperArches.aligner_type ? upperArches.aligner_type : ""}\n${
      upperArches.bracket_removal ? upperArches.bracket_removal : ""
    }${
      (upperArches.bracket_removal && upperArches.cover_lingual_bar) ||
      (upperArches.bracket_removal && upperArches.remove_lingual_bar)
        ? ","
        : ""
    }${upperArches.cover_lingual_bar ? upperArches.cover_lingual_bar : ""}${
      upperArches.remove_lingual_bar && upperArches.cover_lingual_bar ? "," : ""
    }${
      upperArches.remove_lingual_bar ? upperArches.remove_lingual_bar : ""
    }\n\n`
    const lowerData = `${lowerArches.isChecked ? lowerArches.isChecked : ""} ${
      lowerArches.aligner_type ? ":" : ""
    } ${lowerArches.aligner_type ? lowerArches.aligner_type : ""}\n${
      lowerArches.bracket_removal ? lowerArches.bracket_removal : ""
    }${
      (lowerArches.bracket_removal && lowerArches.cover_lingual_bar) ||
      (lowerArches.bracket_removal && lowerArches.remove_lingual_bar)
        ? ","
        : ""
    }${lowerArches.cover_lingual_bar ? lowerArches.cover_lingual_bar : ""}${
      lowerArches.remove_lingual_bar && lowerArches.cover_lingual_bar ? "," : ""
    }${lowerArches.remove_lingual_bar ? lowerArches.remove_lingual_bar : ""}`
    defaultdata.arches_to_treat = upperData + lowerData
  } else if (
    defaultdata.arches_to_treat &&
    !defaultdata.arches_to_treat.lower
  ) {
    const upperArches = defaultdata.arches_to_treat.upper
    const upperData = `${upperArches.isChecked ? upperArches.isChecked : ""} ${
      upperArches.aligner_type ? ":" : ""
    } ${upperArches.aligner_type ? upperArches.aligner_type : ""}\n${
      upperArches.bracket_removal ? upperArches.bracket_removal : ""
    }${
      (upperArches.bracket_removal && upperArches.cover_lingual_bar) ||
      (upperArches.bracket_removal && upperArches.remove_lingual_bar)
        ? ","
        : ""
    }${upperArches.cover_lingual_bar ? upperArches.cover_lingual_bar : ""}${
      upperArches.remove_lingual_bar && upperArches.cover_lingual_bar ? "," : ""
    }${
      upperArches.remove_lingual_bar ? upperArches.remove_lingual_bar : ""
    }\n\n`
    defaultdata.arches_to_treat = upperData
  } else if(defaultdata.arches_to_treat) {
    const lowerArches = defaultdata.arches_to_treat.lower
    const lowerData = `${lowerArches.isChecked ? lowerArches.isChecked : ""} ${
      lowerArches.aligner_type ? ":" : ""
    } ${lowerArches.aligner_type ? lowerArches.aligner_type : ""}\n${
      lowerArches.bracket_removal ? lowerArches.bracket_removal : ""
    }${
      (lowerArches.bracket_removal && lowerArches.cover_lingual_bar) ||
      (lowerArches.bracket_removal && lowerArches.remove_lingual_bar)
        ? ","
        : ""
    }${lowerArches.cover_lingual_bar ? lowerArches.cover_lingual_bar : ""}${
      lowerArches.remove_lingual_bar && lowerArches.cover_lingual_bar ? "," : ""
    }${lowerArches.remove_lingual_bar ? lowerArches.remove_lingual_bar : ""}`
    defaultdata.arches_to_treat = lowerData
  }
  if (defaultdata.wear_time_of_aligners) {
    if (defaultdata.wear_time_of_aligners.value === "custom") {
      defaultdata.wear_time_of_aligners = convertSummaryValue(
        defaultdata.wear_time_of_aligners.stage,
        " Days",
      )
    }
  }
  if (defaultdata.stage_ipr === "Not applicable") {
    defaultdata.set_ipr_staging = ""
    defaultdata.order_of_priority = []
    defaultdata.max_interproximal_conatct = ""
  }
  if (defaultdata.attachments?.value === "Yes") {
    defaultdata.attachments = {
      ...defaultdata.attachments,
      stage: "Stage: " + defaultdata.attachments.stage,
    }
  }
  if (defaultdata.smile_design?.stage) {
    defaultdata.smile_design = {
      ...defaultdata.smile_design,
      stage: defaultdata.smile_design?.stage + " mm",
    }
  }

  if (defaultdata.current_aligner_number) {
    const upperData = defaultdata.current_aligner_number.Upper
      ? `Upper: ${defaultdata.current_aligner_number.Upper}`
      : ""
    const lowerData = defaultdata.current_aligner_number.Lower
      ? `Lower: ${defaultdata.current_aligner_number.Lower}`
      : ""
    defaultdata.current_aligner_number =
      upperData + (upperData ? `\n` : "") + lowerData
  }
  return defaultdata
}

const generateSummarySchema = (
  prescriptionSchema: any,
  templateSchema: any,
) => {
  const props = {
    ...prescriptionSchema.properties,
    ...templateSchema.properties,
  }
  const summaryProperties = {}
  for (const prop in props) {
    const propDetails = props[prop]
    const summaryProperty = {
      title: propDetails.label || propDetails.title,
      type: "string",
      isSummary: true,
      checkbox:
        propDetails.checkboxes || null || propDetails.checkboxGroup?.checkboxes,
    }

    if (
      [
        "deep_bite_template_instructions",
        "case_instructions",
        "spacing_template_instructions",
        "crowding_template_instructions",
        "open_bite_template_instructions",
        "limited_treatment_template_instructions",
      ].includes(prop)
    ) {
      summaryProperty.fontweight = 400
    }
    summaryProperties[prop] = summaryProperty
  }

  const summarySchema = {
    type: "object",
    properties: summaryProperties,
  }
  return summarySchema
}

const generateSummaryUiSchema = (
  prescriptionUiSchema: any,
  prescriptionData: any,
  otherformsUI: any,
  otherData: any,
  isPdf: boolean,
) => {
  const prescriptionSchema = {
    type: "Group",
    label: "Review Prescription",
    elements: [],
  }
  let templateVal =
  prescriptionData && prescriptionData.smart_rx_template && prescriptionData.smart_rx_template.replace(/\s+/g, "").toLowerCase() +
    "_template_instructions"
  if (templateVal === "deepbite_template_instructions") {
    templateVal = "deep_bite_template_instructions"
  } else if (templateVal === "limitedtreatment_template_instructions") {
    templateVal = "limited_treatment_template_instructions"
  } else if (templateVal === "openbite_template_instructions") {
    templateVal = "open_bite_template_instructions"
  }
  prescriptionUiSchema?.elements?.forEach((element) => {
    const propName = element.scope.split("/properties/")[1]

    if (
      (propName in prescriptionData &&
        ![
          "crowding_template_instructions",
          "limited_treatment_template_instructions",
          "deep_bite_template_instructions",
          "open_bite_template_instructions",
          "spacing_template_instructions",
        ].includes(propName)) ||
      templateVal === propName
    ) {
      const groupElement = {
        type: "isSummary",
        scope: element.scope,
      }
      prescriptionSchema.elements.push(groupElement)
    }
  })

  const otherFormUISchema = {
    type: isPdf ? "Group" : "AccordionGroup",
    label: prescriptionData.smart_rx_template + " smartrx template",
    elements: [],
  }

  if(Array.isArray(otherformsUI.elements)){
    for (const element of otherformsUI.elements) {
      if (element.type === "Group") {
        const summaryGroup = {
          type: "Group",
          label: element.label,
          isSummary: true,
          elements: [],
        }

        for (const groupElement of element.elements) {
          const propertyName = groupElement.scope.substring(1)

          const summaryElement = {
            type: "isSummary",
            label: true,
            scope: groupElement.scope,
          }
          // eslint-disable-next-line no-prototype-builtins
          if (otherData.hasOwnProperty(propertyName)) {
            summaryElement.default = otherData[propertyName]
          }

          summaryGroup.elements.push(summaryElement)
        }

        otherFormUISchema.elements.push(summaryGroup)
      }
    }
  }
  for (let i = 1; i < otherFormUISchema.elements.length; i++) {
    otherFormUISchema.elements[i]["top"] = "12px"
  }

  const res = {
    type: "VerticalLayout",
    elements: [prescriptionSchema, otherFormUISchema],
  }
  return res
}

const generateRfSummarySchema = (prescriptionSchema: any) => {
  const props = {
    ...prescriptionSchema.properties,
  }
  const summaryProperties = {}
  for (const prop in props) {
    const propDetails = props[prop]
    const summaryProperty = {
      title: propDetails.label || propDetails.title,
      type: "string",
      isSummary: true,
      checkbox:
        propDetails.checkboxes || null || propDetails.checkboxGroup?.checkboxes,
    }

    if (
      [
        "deep_bite_template_instructions",
        "case_instructions",
        "spacing_template_instructions",
        "crowding_template_instructions",
        "open_bite_template_instructions",
        "limited_treatment_template_instructions",
      ].includes(prop)
    ) {
      summaryProperty.fontweight = 400
    }
    summaryProperties[prop] = summaryProperty
  }

  const summarySchema = {
    type: "object",
    properties: summaryProperties,
  }
  return summarySchema
}

const generateRfSummaryUiSchema = (prescriptionUiSchema: any) => {
  const prescriptionSchema = {
    type: "Group",
    label: "Review Prescription",
    elements: [],
  }
  for (const element of prescriptionUiSchema.elements) {
    if (element.type === "Group") {
      const summaryGroup = {
        type: "Group",
        label: element.label,
        isSummary: true,
        elements: [],
      }

      for (const groupElement of element.elements) {
        const summaryElement = {
          type: "isSummary",
          label: true,
          scope: groupElement.scope,
        }

        summaryGroup.elements.push(summaryElement)
      }
      prescriptionSchema.elements.push(summaryGroup)
    }
  }

  const res = {
    type: "VerticalLayout",
    elements: [prescriptionSchema],
  }
  return res
}

const generateRfSummaryData = (defaultdata: any) => {
  const updatedData = { ...defaultdata }

  if (updatedData?.current_aligner_number && typeof updatedData.current_aligner_number === 'object') {
    const upperData = updatedData.current_aligner_number.Upper
      ? `Upper: ${updatedData.current_aligner_number.Upper}`
      : ""
    const lowerData = updatedData.current_aligner_number.Lower
      ? `Lower: ${updatedData.current_aligner_number.Lower}`
      : ""
    const formattedData = [upperData, lowerData].filter(Boolean).join("\n");

    if (formattedData) {
      updatedData.current_aligner_number = formattedData
    }
  }

  if (updatedData.add_bite_ramps) {
    updatedData.add_bite_ramps = convertObjectToString(updatedData.add_bite_ramps)
  }

  return updatedData
}

function convertObjectToString(obj: object) {
  let data = obj?.bite_ramp
  // Define a mapping for the new labels
  const labelMap = {
    upper_right: "RU",
    lower_right: "RL",
    upper_left: "LU",
    lower_left: "LL",
  }

  // Format each section according to the new label names
  const formattedSections = [
    `${labelMap["upper_right"]}: ${data.upper_right.length > 0 ? data.upper_right.join(",") : "-"}`, 
    `${labelMap["upper_left"]}: ${data.upper_left.length > 0 ? data.upper_left.join(",") : "-"}`, 
    `${labelMap["lower_right"]}: ${data.lower_right.length > 0 ? data.lower_right.join(",") : "-"}`,
    `${labelMap["lower_left"]}: ${data.lower_left.length > 0 ? data.lower_left.join(",") : "-"}`,    
  ]

  // Join with line break between upper and lower sections
  return `${formattedSections[0]} | ${formattedSections[1]}\n${formattedSections[2]} | ${formattedSections[3]}`;
}

export {
  generateSummaryData,
  generateSummarySchema,
  generateSummaryUiSchema,
  generateRfSummarySchema,
  generateRfSummaryUiSchema,
  generateRfSummaryData,
}
