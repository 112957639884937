import { FC, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useLocation, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from "@react-pdf/renderer"
import html2canvas from "html2canvas"
import { PDFDocument } from "pdf-lib"

import { ULoading, UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { postMessageData } from "@/core/app/slices/banners/bannerThunkApi"
import { getCaseByPatient, updateCaseById } from "@/core/app/slices/case/caseThunkApi"
import { resetAction } from "@/core/app/slices/clinical/clinicalSlice"
import { postMessageApi } from "@/core/app/slices/clinical/clinicalThunkApi"
import { updateOrder } from "@/core/app/slices/order/orderThunkApi"
import { downloadDentalChart } from "@/core/app/slices/records/xrays/xraysThunkApi"
import {
  fetchFormByCaseIdWithMergeData,
  saveUassistSummary,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys, parseToObject } from "@/core/utils/formatters"
import { caseManagement } from "@/gluelayer"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "@/routes/status.route.config"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import {
  generateRfSummaryData,
  generateRfSummarySchema,
  generateRfSummaryUiSchema,
  generateSummaryData,
  generateSummarySchema,
  generateSummaryUiSchema,
} from "@/rx-form/Json/Summary"

import { NoUassistPopup } from "./NoUassistPopup"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { ICase } from "@/core/model/interface/ICase"

const A4Height = 792 // 792px is the height of a standard letter-sized page in points (11 inches).
const A4Margin = 50

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 1,
    paddingTop: `${A4Margin}px`,
    paddingBottom: `${A4Margin}px`,
  },
})

const addPagesToMergedPDF = async (mergedPdfDoc, pdfDoc, pageIndices) => {
  for (const index of pageIndices) {
    const [pdfPage] = await mergedPdfDoc.copyPages(pdfDoc, [index])
    mergedPdfDoc.addPage(pdfPage)
  }
}

const exportComponentAsPDF = (
  componentRef: React.RefObject<HTMLDivElement>,
  dispatch: any,
  location: any,
  archesToTreat: any,
  t: any,
  navigate: any,
  dentalChart: Blob,
  payload: {
    patientId: string
    caseId: string
  },
  isRefinement: boolean,
  isRetainer: boolean,
  rxPrescription: any,
  setIsPdfLoading: (o: boolean) => void,
  needToSubmit: boolean,
  setActionType: (val: string) => void,
  placeUassistOrderRefinement: (blob: Blob) => void,
  caseDetail?: ICase
) => {
  if (!componentRef.current) {
    console.warn("the component which is exported to PDF is null !")
    setIsPdfLoading(false)
    return
  }
  componentRef.current.style.display = "block"

  // Now you have the Blob object, and you can use it as needed.
  const width = componentRef.current?.clientWidth
  // const height = componentRef.current?.clientHeight

  // calculate page height range without cutting-out elements in last
  let parent = componentRef.current
  let pageDimensionArr = []
  let currentPage = 0
  const pageH = A4Height - 2 * A4Margin
  let lastScannedH = 0
  let parentDimension = parent.getBoundingClientRect()

  parent.querySelectorAll(".summary-data-row").forEach((el, index) => {
    if (!pageDimensionArr[currentPage]) {
      pageDimensionArr[currentPage] = {
        start: lastScannedH,
        end: lastScannedH + pageH,
        lastChildEl: null,
      }
    }
    let elDimension = el.getBoundingClientRect()
    let elRelTop = Math.abs(parentDimension.top - elDimension.top)
    let elRelBottom = elRelTop + elDimension.height

    if (pageDimensionArr[currentPage].end > elRelBottom) {
      // element is within render range
      lastScannedH = elRelBottom
      pageDimensionArr[currentPage].lastChildEl = el
    } else {
      // element is NOT within render range
      lastScannedH = elRelTop
      pageDimensionArr[currentPage].end = elRelTop
      pageDimensionArr[currentPage + 1] = {
        start: elRelTop,
        end: elRelTop + pageH,
        lastChildEl: el,
      }
      currentPage += 1
    }
  })

  const imgPromises = []

  for (let i = 0; i < pageDimensionArr.length; i++) {
    const imgPromise = html2canvas(componentRef.current, {
      y: pageDimensionArr[i].start,
      height: pageDimensionArr[i].end - pageDimensionArr[i].start,
    }).then((canvas) => {
      return canvas.toDataURL("image/png")
    })

    imgPromises.push(imgPromise)
  }

  Promise.all(imgPromises).then((imgDataArray) => {
    const MakePDFComponent = () => (
      <Document>
        {imgDataArray.map((imgData, i) => {
          return (
            <Page key={i} style={styles.page} size="letter">
              <View>
                <Image
                  style={{ width, height: `${pageH}px` }}
                  source={imgData}
                  cache={true}
                />
              </View>
            </Page>
          )
        })}
      </Document>
    )

    pdf(<MakePDFComponent />)
      .toBlob()
      .then(async (blob) => {
        if (isRefinement) {
          placeUassistOrderRefinement(blob)
        } else {
          const pdfDoc1 = await PDFDocument.load(await blob.arrayBuffer())
          const pdfDoc2 = dentalChart
            ? await PDFDocument.load(await dentalChart.arrayBuffer())
            : null

          const mergedPdfDoc = await PDFDocument.create()

          const pageIndices1 = Array.from(
            { length: pdfDoc1.getPageCount() },
            (_, i) => i,
          )

          // Copy and add pages from pdfDoc1 and pdfDoc2
          await addPagesToMergedPDF(mergedPdfDoc, pdfDoc1, pageIndices1)

          if (pdfDoc2) {
            const pageIndices2 = Array.from(
              { length: pdfDoc2.getPageCount() },
              (_, i) => i,
            )
            await addPagesToMergedPDF(mergedPdfDoc, pdfDoc2, pageIndices2)
          }

          // Save the merged PDF
          const mergedPdfBlob = new Blob([await mergedPdfDoc.save()], {
            type: "application/pdf",
          })

          // Use this block to download summary to file manager
          // const link = document.createElement("a")
          // link.href = URL.createObjectURL(mergedPdfBlob)
          // link.download = "summary.pdf"
          // link.click()

          // Create form data and upload to api
          let pdfName = ""
          isRetainer
            ? (pdfName = "retainer-summary.pdf")
            : (pdfName = "summary.pdf")
          const formData = new FormData()
          formData.append(
            "attachment",
            new File([mergedPdfBlob], pdfName, {
              type: "application/pdf",
            }),
          )
          dispatch(
            saveUassistSummary({
              patientId: payload.patientId,
              caseId: payload.caseId,
              payload: formData,
              isRefinement,
              isRetainer,
            }),
          ).then((res: any) => {
            
            let upper = ""
            let lower = ""
            let bracket_removal = ""

            if (isRetainer) {
              lower = rxPrescription.data.arches_to_treat.includes("lower")
                ? "R"
                : ""
              upper = rxPrescription.data.arches_to_treat.includes("upper")
                ? "R"
                : ""
              bracket_removal =
                rxPrescription.data.bracket_removal?.value?.includes("yes")
            } else {
              upper = archesToTreat.upper
                ? archesToTreat.upper.aligner_type === "Aligner"
                  ? "A"
                  : "R"
                : ""

              lower = archesToTreat.lower
                ? archesToTreat.lower.aligner_type === "Aligner"
                  ? "A"
                  : "R"
                : ""
            }
            const udesign_json = JSON.parse(caseDetail.udesign_json)
            const newUdesignJson = {
              ...udesign_json,
              UpperType: upper,
              LowerType: lower,
            }
            setIsPdfLoading(false)
            if (!needToSubmit) {
              let patientId: string = payload.patientId
              let caseId: string = payload.caseId
              dispatch(
                updateCaseById({
                  patientId,
                  caseId,
                  payload: {
                    udesign_category: `${upper ? upper : "--"}/${
                      lower ? lower : "--"
                    }`,
                    case_extra_attrs: JSON.stringify(newUdesignJson),
                  },
                }),
              )
              return
            }
            const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
            if (res.payload.status === "Success") {
              if (
                location.pathname.includes(
                  `${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
                ) ||
                location.pathname.includes(
                  `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
                )
              ) {
                dispatch(
                  postMessageData({
                    patientId: payload.patientId,
                    caseId: payload.caseId.toString(),
                    payload: {
                      message: "Rx Form updated",
                    },
                  }),
                ).then((res) => {
                  if (res && res.payload && res.payload.status === "Success") {
                    dispatch(getCaseByPatient({ patientId: payload.patientId }))
                    navigate(`/overview/patient/${payload.patientId}/`)
                  }
                })
              } else {
                dispatch(
                  updateOrder({
                    caseId: payload.caseId,
                    type: isRetainer
                      ? bracket_removal
                        ? "Bracket Removal Uassist"
                        : "Retainer Uassist"
                      : "uAssist",
                    upper: upper,
                    lower: lower,
                  }),
                ).then((result: any) => {
                  setIsPdfLoading(false)
                  if (result.payload.status === "Success") {
                    navigate(
                      `/uassist/patient/${payload.patientId}/case/${payload.caseId}/summary/confirmation`,
                    )
                  } else {
                    dispatch(
                      setAlert({
                        message: errorMessage,
                        isError: true,
                      }),
                    )
                  }
                })
              }
            } else {
              setIsPdfLoading(false)
              dispatch(
                setAlert({
                  message: errorMessage,
                  isError: true,
                }),
              )
            }
          })
        }
      })
  })
}

const Summary: FC<{
  isPreview?: boolean
  hideSummary?: () => void
  isRefinement?: boolean
  isRetainer?: boolean
  setDownloadPdf?: (val: boolean) => void
  setActionType?: (val: string) => void
  needToSubmit: boolean
  reviewNum?: number
  showSubmitBtn?: boolean
  noPdfDownload?: boolean
  smartRxGeneratePDF?: boolean
  setSmartRxGeneratePDF?: (val: boolean) => void
}> = ({
  isPreview,
  hideSummary,
  isRefinement,
  isRetainer,
  setDownloadPdf,
  setActionType,
  needToSubmit,
  reviewNum,
  showSubmitBtn,
  noPdfDownload=false,
  smartRxGeneratePDF,
  setSmartRxGeneratePDF
}) => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [uiSchema, setUiSchema] = useState({})
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const { caseId, patientId } = useParams()
  const { formTemplateList, rxJsonForm, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { clinicalSettings } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { dentalChart } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const contentToPDF = useRef(null)
  const [isPdfLoading, setIsPdfLoading] = useState(false)

  useEffect(() => {
    const handleEffect = async () => {
      const tempFormId = parseToObject(rxPrescription?.data)
        ?.smart_rx_template_id?.id
      if (tempFormId) {
        await dispatch(
          fetchFormByCaseIdWithMergeData({
            formId: tempFormId,
            caseId: caseId,
            getSavedData: true,
            patientId: patientId,
            formType: "SMARTRX",
            uassistRound: 0,
          }),
        )
      }
      if (!needToSubmit || reviewNum > 1) {
        setIsPdfLoading(true)
      }
    }

    handleEffect()

    window.scrollTo({ top: 0 })
  }, [needToSubmit, reviewNum, rxPrescription, dispatch])

  const placeUassistOrderRefinement = (blob: Blob) => {
    // const link = document.createElement("a")
    // link.href = URL.createObjectURL(blob)
    // link.download = "refinement.pdf"
    // link.click()
    const formData = new FormData()
    formData.append(
      "attachment",
      new File([blob], `refinement.pdf`, {
        type: "application/pdf",
      }),
    )
    formData.append("is_refinement", "1")
    dispatch(
      saveUassistSummary({
        patientId: patientId,
        caseId: caseId,
        payload: formData,
        isRefinement,
      }),
    ).then((res: any) => {
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      const upper = arches && arches[0] 
      const lower = arches && arches[1]
      let upperStr = ""
      let lowerStr = ""
      const getUassistFormIdByName = (name: string) =>
        getFormByName(formTemplateList, "UASSIST", name).id

      if (
        rxPrescription.id === getUassistFormIdByName(FORM_CONSTANT.REFINEMENT)
      ) {
        lowerStr = rxPrescription.data.arches_to_treat.includes("lower")
          ? ["A", "R"].includes(lower)
            ? lower
            : upper
          : ""
        upperStr = rxPrescription.data.arches_to_treat.includes("upper")
          ? ["A", "R"].includes(upper)
            ? upper
            : lower
          : ""
      } else if (
        rxPrescription.id === getUassistFormIdByName(FORM_CONSTANT.REFINEMENT_2)
      ) {
        upperStr = rxPrescription.data.arches_to_treat.upper
          ? rxPrescription.data.arches_to_treat.upper.aligner_type === "Aligner"
            ? "A"
            : "R"
          : ""

        lowerStr = rxPrescription.data.arches_to_treat.lower
          ? rxPrescription.data.arches_to_treat.lower.aligner_type === "Aligner"
            ? "A"
            : "R"
          : ""
      }
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const newUdesignJson = {
        ...udesign_json,
        UpperType: upperStr,
        LowerType: lowerStr,
      }
      if (!needToSubmit) {
        dispatch(
          updateCaseById({
            patientId,
            caseId,
            payload: {
              udesign_category: `${upperStr ? upperStr : "--"}/${
                lowerStr ? lowerStr : "--"
              }`,
              case_extra_attrs: JSON.stringify(newUdesignJson),
            },
          }),
        )
        if(smartRxGeneratePDF) {
          setDownloadPdf(false)
          setSmartRxGeneratePDF(false)
          setActionType("edit")
        }
        setIsPdfLoading(false)
        return
      }
      const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
      if (res.payload.status === "Success") {
        if (
          location.pathname.includes(
            `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
          )
        ) {
          dispatch(
            postMessageData({
              patientId: patientId,
              caseId: caseId.toString(),
              payload: {
                message: "Rx Form updated",
              },
            }),
          ).then((res) => {
            if (res && res.payload && res.payload.status === "Success") {
              dispatch(getCaseByPatient({ patientId: patientId }))
              navigate(`/overview/patient/${patientId}/`)
            }
          })
        } else {
            dispatch(
              updateOrder({
                caseId: caseId,
                type: "Refinement uAssist",
                upper: upperStr,
                lower: lowerStr,
              }),
            ).then((result: any) => {
              setIsPdfLoading(false)
              if (result.payload.status === "Success") {
                navigate(
                  `/overview/patient/${patientId}/case/${caseId}/refinement/confirmation`,
                )
              } else {
                dispatch(
                  setAlert({
                    message: t("uassist.uAssistForm.refinementsavefailed"),
                    isError: true,
                  }),
                )
              }
            })
        }
      } else {
        dispatch(
          setAlert({
            message: errorMessage,
            isError: true,
          }),
        )
      }
    })
  }

  const patientDetail = [
    {
      label: "Patient Name",
      value: patientData?.firstName + " " + patientData?.lastName,
    },
    {
      label: "Patient Id",
      value: patientId,
    },
    {
      label: "Case Id",
      value: caseId,
    },
  ]

  // useEffect(() => {
  //   if (!formSubmitted) return
  //   postSettingTozips()
  // }, [formSubmitted])

  // Todo: to be reviewed
  useEffect(() => {
    if (checkIfObjectHasKeys(rxJsonForm.uischema)) {
      const uiSchema = {
        ...rxJsonForm.uischema,
        elements: [...rxJsonForm.uischema.elements].filter(
          (element: any) => element.label !== "Additional Instructions",
        ),
      }
      setUiSchema({ ...uiSchema })
    }
  }, [rxJsonForm])

  const navigateBack = () => {
    if (
      location.pathname.includes(
        `${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
      )
    ) {
      navigate(
        `/overview/patient/${patientId}/case/${caseId}${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
      )
    } else {
      navigate(`/uassist/patient/${patientId}/case/${caseId}/prescription`)
    }
  }

  const postSettingTozips = () => {
    caseManagement.saveJsonDataToZip(
      JSON.stringify(clinicalSettings),
      "uassist_team_settings.json",
      "Setting Data2",
    )
  }

  useEffect(() => {
    if (isPdfLoading) {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        exportComponentAsPDF(
          contentToPDF,
          dispatch,
          location,
          rxPrescription.data.arches_to_treat,
          t,
          navigate,
          dentalChart,
          {
            patientId: patientId,
            caseId: caseId,
          },
          isRefinement,
          isRetainer,
          rxPrescription,
          setIsPdfLoading,
          needToSubmit,
          setActionType,
          placeUassistOrderRefinement,
          caseDetail
        )
      }
    }
  }, [isPdfLoading])

  return (
    <>
      {/* <ULoading isLoading={isPdfLoading && !showNoUassistAlert} /> */}
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsPdfLoading}
      />
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          minHeight: "515px",
          padding: 0,
          justifyContent: !isPreview && "space-between",
        }}
      >
        {/* {isPreview && (
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "end",
              height: "64px",
              position: "sticky",
              top: 0,
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "white",
              marginRight: "-24px",
            }}
          >
            <CloseIcon
              fontSize={"medium"}
              sx={{
                color: "rgba(0, 0, 0, 0.56)",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={hideSummary}
            />
          </Box>
        )} */}
        {
          <Box component={"div"}>
            {/* <UText
            variant={"h4"}
            component={"div"}
            sxProp={{ textAlign: "center" }}
          >
            <Trans components={{ newLine: <br /> }}>
              {isRefinement
                ? "uassist.refinement.title"
                : isRetainer
                ? "uassist.retainer.title"
                : "uassist.summary.title"}
            </Trans>
          </UText> */}
            {!isPreview && (
              <Box sx={{ height: "24px" }}>
                <UText
                  component={"div"}
                  variant={"body2"}
                  sxProp={{ textAlign: "center", color: "rgb(0,0,0,0.6)" }}
                >
                  {t("uassist.summary.summarydesc")}
                </UText>
              </Box>
            )}
          </Box>
        }
        {!smartRxGeneratePDF && (rxPrescription.id && rxJsonForm.id
          ? !!(
              checkIfObjectHasKeys(rxJsonForm.schema) &&
              checkIfObjectHasKeys(uiSchema) &&
              checkIfObjectHasKeys(rxJsonForm.data) &&
              (isRefinement ||
                isRetainer ||
                (checkIfObjectHasKeys(rxPrescription.schema) &&
                  checkIfObjectHasKeys(rxPrescription.uischema) &&
                  checkIfObjectHasKeys(rxPrescription.data)))
            ) && (
              <Box sx={{ pt: "4px", mt: 1 }}>
                <JsonFormBuilder
                  schema={generateSummarySchema(
                    rxPrescription.schema,
                    rxJsonForm.schema,
                  )}
                  uischema={generateSummaryUiSchema(
                    rxPrescription.uischema,
                    rxPrescription.data,
                    uiSchema,
                    rxJsonForm.data,
                    false,
                  )}
                  defaultData={generateSummaryData({
                    ...rxPrescription.data,
                    ...rxJsonForm.data,
                  })}
                />
              </Box>
            )
          : !!(
              checkIfObjectHasKeys(rxPrescription.schema) &&
              checkIfObjectHasKeys(rxPrescription.uischema) &&
              checkIfObjectHasKeys(rxPrescription.data)
            ) && (
              <Box sx={{ pt: "4px", mt: 1 }}>
                <JsonFormBuilder
                  schema={generateRfSummarySchema(rxPrescription.schema)}
                  uischema={generateRfSummaryUiSchema(rxPrescription.uischema)}
                  defaultData={generateRfSummaryData(rxPrescription.data)}
                />
              </Box>
            ))}
        {((!isPreview && !isRetainer && needToSubmit) || showSubmitBtn) && (
          <Box
            component={"div"}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              mt: "28px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setDownloadPdf(false)}
              sx={{ width: "96px", height: "36px" }}
            >
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
              {t("button.edit")}
            </Button>
            <Box>
              {/* <Button
                variant={"text"}
                onClick={() =>
                  dispatch(
                    setAlert({
                      message: t("uassist.uAssistForm.uassistCaseSaved"),
                    }),
                  )
                }
                sx={{
                  minWidth: "auto",
                  height: "auto",
                  width: "auto",
                  lineHeight: "24px",
                  marginRight: "16px",
                }}
              >
                {t("button.save")}
              </Button> */}
              <Button
                variant={"contained"}
                sx={{ width: "196px", height: "36px" }}
                onClick={() => {
                  setIsPdfLoading(true)
                }}
              >
                {t("button.submittoUassist")}
                <ChevronRightIcon
                  fontSize={"small"}
                  sx={{ height: "20px", width: "20px", marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
      {!isPreview && !noPdfDownload && (
        <Grid>
          <Box
            component={"div"}
            ref={contentToPDF}
            sx={{
              zIndex: -1,
              position: "fixed",
              top: "120vh",
              display: "none",
            }}
          >
            <Box component={"div"}>
              <UText
                variant={"h4"}
                component={"div"}
                sxProp={{ textAlign: "center" }}
              >
                <Trans components={{ newLine: <br /> }}>
                  {isRetainer
                    ? "uassist.retainer.title"
                    : "uassist.summary.refinementTitle"}
                </Trans>
              </UText>
            </Box>
            {patientDetail.map((data) => {
              return (
                <Box
                  sx={{
                    py: 1,
                    mb: "4px",
                    display: "flex",
                    flexDirection: "row",
                    pl: 1,
                  }}
                  key={data.label}
                >
                  <UText
                    variant={"body2"}
                    sxProp={{
                      width: 200,
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    {data.label}
                  </UText>
                  <Box>
                    <UText
                      component={"div"}
                      variant={"subtitle2"}
                      sxProp={{
                        ml: 1,
                        lineHeight: "16px",
                        color: "text.primary",
                      }}
                    >
                      {data.value}
                    </UText>
                  </Box>
                </Box>
              )
            })}
            {rxPrescription.id && rxJsonForm.id
              ? !!(
                  checkIfObjectHasKeys(rxJsonForm.schema) &&
                  checkIfObjectHasKeys(uiSchema) &&
                  checkIfObjectHasKeys(rxJsonForm.data) &&
                  (isRefinement ||
                    isRetainer ||
                    (checkIfObjectHasKeys(rxPrescription.schema) &&
                      checkIfObjectHasKeys(rxPrescription.uischema) &&
                      checkIfObjectHasKeys(rxPrescription.data)))
                ) && (
                  <>
                    <Box sx={{ pt: "4px", mt: 1 }}>
                      <JsonFormBuilder
                        schema={generateSummarySchema(
                          rxPrescription.schema,
                          rxJsonForm.schema,
                        )}
                        uischema={generateSummaryUiSchema(
                          rxPrescription.uischema,
                          rxPrescription.data,
                          uiSchema,
                          rxJsonForm.data,
                          true,
                        )}
                        defaultData={generateSummaryData({
                          ...rxPrescription.data,
                          ...rxJsonForm.data,
                        })}
                      />
                    </Box>
                  </>
                )
              : !!(
                  checkIfObjectHasKeys(rxPrescription.schema) &&
                  checkIfObjectHasKeys(rxPrescription.uischema) &&
                  checkIfObjectHasKeys(rxPrescription.data)
                ) && (
                  <>
                    <Box sx={{ pt: "4px", mt: 1 }}>
                      <JsonFormBuilder
                        schema={generateRfSummarySchema(rxPrescription.schema)}
                        uischema={generateRfSummaryUiSchema(
                          rxPrescription.uischema,
                        )}
                        defaultData={generateRfSummaryData(rxPrescription.data)}
                      />
                    </Box>
                  </>
                )}
          </Box>
        </Grid>
      )}
    </>
  )
}

export default Summary
