import { CapitalizeString } from "../../../src/modules/Patient/PatientList/util/commonUtil"

import {
  IAlignerOrder,
  PACKAGETYPE,
  PromoCode,
  SHIPPINGMETHOD,
  ShipToAddress,
} from "./interface/AlignerOrder"
import Deserialize from "./interface/Deserialize"
import { IActiveOrders, IOrder } from "./interface/IOrder"
import { IShippingAddress } from "./interface/IShippingAddress"

export class ShippingAddress implements IShippingAddress, Deserialize {
  id: null
  line_1 = ""
  line_2 = ""
  city = ""
  region_id = null
  state: { id: null; short_name: "" }
  zip_code = ""
  country_id = null
  country = { id: null, code_1: "" }
  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.line_1 = input.line_1
      this.line_2 = input.line_2
      this.city = input.city
      this.zip_code = input.zip_code
      if (input.region_id && input.state) {
        this.region_id = input.region_id
        this.state = {
          id: input.state.id,
          short_name: input.state.short_name,
        }
      }
      if (input.country_id && input.state) {
        this.country_id = input.country_id
        this.country = {
          id: input.country.id,
          code_1: input.country.code_1,
        }
      }
    }
  }
}

export interface IUAssistOrder{
  id: number
  planName: string
  createDate: string
  courseCheckNumber: number
}


export class UAssistOrder implements IUAssistOrder, Deserialize{
  id = null
  planName = null
  createDate = null
  courseCheckNumber = null

  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.planName = input.plan_name
      this.createDate = input.create_date
      this.courseCheckNumber = input.course_check_number
    }
    return this
  }
}

export class Order implements IOrder, Deserialize {
  txplanName = ""
  txplanSubname = ""
  alignerCount = null
  retainerCount = null
  alignerMaterial = ""
  upperStageRange = ""
  lowerStageRange = ""
  templateCount = null
  upperTemplateStage = ""
  lowerTemplateStage = ""
  upperRetainerCount = ""
  lowerRetainerCount = ""
  isCustomPackaging = false
  shippingMethod = ""
  orderType = null
  orderDate = ""
  orderId = null
  caseId = null
  orderStatus = ""
  trackingNo = ""
  shippingDate = ""
  updateDate = ""
  treatmentPlanName = ""
  orderNumber: string

  Deserialize(input: any): this {
    if (input) {
      this.txplanName = input.txplan_name
      this.txplanSubname = input.txplan_subname
      this.treatmentPlanName = input.refinement_name
      this.alignerCount = input.aligner_count
      this.alignerMaterial = input.aligner_material

      this.retainerCount = input.retainer_count
      this.upperRetainerCount = input.upper_retainer_stage
      this.lowerRetainerCount = input.lower_retainer_stage

      this.upperStageRange = input.upper_stage_range
      this.lowerStageRange = input.lower_stage_range
      this.templateCount = input.template_count
      this.upperTemplateStage = input.upper_template_stage
      this.lowerTemplateStage = input.lower_template_stage
      this.isCustomPackaging = input.custom_packaging
      if (input.shipping_method) {
        this.shippingMethod = input.shipping_method.name
      }
      if (input.status) {
        this.orderStatus = input.status.name
      }
      this.trackingNo = input.shipment_tracking_no
      this.shippingDate = input.shipping_date
      this.updateDate = input.update_date
      this.orderType = input.type?.name || ""
      this.orderDate = input.order_date
      this.orderId = input.order_id
      this.caseId = input.case_id
      
      if (input.order_no && input.order_no_2) {
        this.orderNumber = input.order_no_2 + "/" + input.order_no
      } else if (input.order_no && !input.order_no_2) {
        this.orderNumber = input.order_no
      } else {
        this.orderNumber = input.order_no_2
      }

    }
    return this
  }
}

export class AlignerOrder implements IAlignerOrder, Deserialize {
  promo_code: PromoCode[]
  shipAddress: ShipToAddress
  bundle: {
    pricing_option: string
    aligner: { remaining: null; requested: null; included: null }
    retainer: { remaining: null; requested: null; included: null }
    template: { remaining: null; requested: null; included: null }
    alignercase: { cost: null; count: null }
    previous_uassist_credit: null
    shipping_price: { type: null; item_total_value: null }
    tax_amt: number
    sub_total: number
    custom_packaging: number
  }
  alacarte: {
    pricing_option: string
    aligner: { cost: null; count: null }
    custom_package: { cost: null; count: null }
    uassist_service: { cost: null; count: null }
    alignercase: { cost: null; count: null }
    retainer: { cost: null; count: null }
    template: { cost: null; count: null }
    shipping_price: { type: null; item_total_value: null }
    tax_amt: number
    sub_total: number
    custom_packaging: number
  }
  packageType: PACKAGETYPE
  shippingMethod: SHIPPINGMETHOD
  total: number
  currency: string
  orderId: number
  subOrderId: number

  private createShipAddress(input: any) {
    const shipVal = input.address?.ship_to_address || {}
    return {
      id: shipVal.id || null,
      business_name: shipVal.business_name || null,
      line_1: shipVal.line_1 || null,
      line_2: shipVal.line_2 || null,
      country: { id: null, code_1: shipVal.country_name || null },
      zip_code: shipVal.zip_code || null,
      phone: shipVal.phone || null,
      city: shipVal.city || null,
      region_id: shipVal.region_id || null,
      state: { id: null, short_name: shipVal.region_name || null },
    }
  }

  private getCostAndCount(bundlekey: any) {
    return {
      cost: bundlekey?.item_per_cost,
      count: bundlekey?.item_count || null,
    }
  }

  private createAligner(bundlekey: any) {
    return {
      requested: bundlekey?.aligner?.requested || null,
      remaining: bundlekey?.aligner?.leftAfterOrder || null,
      included: bundlekey?.aligner?.current || null,
    }
  }

  private createTemplate(bundlekey: any) {
    return {
      requested: bundlekey?.template?.requested || null,
      remaining: bundlekey?.template?.leftAfterOrder || null,
      included: bundlekey?.template?.current || null,
    }
  }

  private createRetainer(bundlekey: any) {
    return {
      requested: bundlekey?.retainer?.requested || null,
      remaining: bundlekey?.retainer?.leftAfterOrder || null,
      included: bundlekey?.retainer?.current || null,
    }
  }

  private getBundleType(
    input: any,
    pricingOptionsMap: Record<string, string>,
  ): string {
    const type = pricingOptionsMap[input.pricing_option] || ""
    if (type === "alacarte") {
      return this.findAlacarteBundle(input) || type
    }
    return type
  }

  private findAlacarteBundle(input: any): string | null {
    const bundleTypes = [
      "Mild",
      "Moderate",
      "Comprehensive",
      "Finishing",
      "2 Retainer",
      "4 Retainer",
    ]
    for (const type of bundleTypes) {
      if (input.quote?.bundles?.[type]?.description) {
        return type
      }
    }
    return null
  }

  private parseBundle(input: any, val: any, bundlekey: any) {
    this.bundle = {
      pricing_option: val?.name ?? "",
      aligner: this.createAligner(bundlekey),
      template: this.createTemplate(bundlekey),
      alignercase: this.getCostAndCount(bundlekey?.aligner_case),
      retainer: this.createRetainer(bundlekey),
      previous_uassist_credit: input.quote?.previous_uassist_credit || null,
      shipping_price: bundlekey?.shipping_price || {
        type: null,
        item_total_value: null,
      },
      tax_amt: bundlekey?.tax?.item_total_value || 0,
      sub_total: bundlekey?.total_value || 0,
      custom_packaging: input.custom_packaging || 0,
    }
  }

  private parseAlacarte(input: any, val: any) {
    this.alacarte = {
      pricing_option: "A La Carte",
      alignercase: this.getCostAndCount(val?.alacarte?.aligner_case),
      aligner: this.getCostAndCount(val?.alacarte?.aligner),
      retainer: this.getCostAndCount(val?.alacarte?.retainer),
      shipping_price: val?.alacarte?.shipping_price || {
        type: null,
        item_total_value: null,
      },
      tax_amt: val?.alacarte?.tax?.item_total_value || 0,
      sub_total: val?.alacarte?.total_value || 0,
      custom_packaging: input.custom_packaging || 0,
      custom_package: this.getCostAndCount(val?.alacarte?.custom_packaging),
      uassist_service: this.getCostAndCount(val?.alacarte?.uassist),
      template: this.getCostAndCount(val?.alacarte?.template),
    }
  }
  Deserialize(input: any): this {
    if (input) {
      let bundle_type = ""
      let val: any
      let bundlekey: any

      const pricingOptionsMap: Record<string, string> = {
        MILD: "Mild",
        MODERATE: "Moderate",
        COMPREHENSIVE: "Comprehensive",
        FINISHING: "Finishing",
        "2 RETAINER": "2 Retainer",
        "4 RETAINER": "4 Retainer",
        ALACARTE: "alacarte",
      }

      bundle_type = this.getBundleType(input, pricingOptionsMap)

      if (bundle_type !== "alacarte") {
        val = input.quote?.bundles?.[bundle_type]
        bundlekey = input.quote?.bundles?.[bundle_type]?.description
      } else {
        val = input.quote
        bundlekey = input.quote?.[bundle_type]
      }
      if (val?.name && bundle_type !== "alacarte") {
        this.parseBundle(input, val, bundlekey)
      }
      if (val?.alacarte) {
        this.parseAlacarte(input, val)
      }
      this.shipAddress = this.createShipAddress(input)

      this.packageType = input.package_type

      if (input.shipping_method) {
        this.shippingMethod = input.shipping_method.name
      }

      this.total = input.quote?.final_total || 0
      this.currency = input.quote?.currency || ""
      this.orderId = input.id
      this.subOrderId = input.sub_order_id || null
      this.promo_code = input?.quote?.promos || []
    }

    return this
  }
}

export class ActiveOrders implements IActiveOrders, Deserialize {
  shipmentTrackingNo: string
  createDate: string
  caseDisposition: string
  first: string
  last: string
  orderNumber: string
  orderTypeName: string
  lowerLasttoothtocover: string
  upperLasttoothtocover: string
  shippingMethod: string
  shippingDate: string
  orderDate: string
  updateDate: string
  estimationShipDate: string
  upperStageRange: string
  lowerStageRange: string
  upperRetainerStage: string
  lowerRetainerStage: string
  upperTemplateStage: string
  lowerTemplateStage: string
  patientId: number
  pricingOption: string
  treatmentName: string
  estimatedDelivery: string
  caseId: string | number
  id: number

  Deserialize(input: any): this {
    if (input) {
      this.id = input?.order_id
      this.caseId = input.case_id
      this.shipmentTrackingNo = input?.shipment_tracking_no
      this.estimationShipDate = input?.delivery_eta
      this.createDate = input.order_date
      this.caseDisposition = input.status.name
        .toUpperCase()
        .replace(/\s+/g, "_")
      this.first = CapitalizeString(input.patient_first_name)
      this.last = CapitalizeString(input.patient_last_name)
      this.patientId = input.patient_id
      this.treatmentName = input?.treatment_plan_name
      this.estimatedDelivery = input.ship_date_eta
      if (input.pricing_option) {
        this.pricingOption =
          input.pricing_option === "ALACARTE"
            ? "A La Carte"
            : CapitalizeString(input.pricing_option)
      }
      if (input.order_no && input.order_no_2) {
        this.orderNumber = input.order_no_2 + "/" + input.order_no
      } else if (input.order_no && !input.order_no_2) {
        this.orderNumber = input.order_no
      } else {
        this.orderNumber = input.order_no_2
      }
      if (input.type) {
        this.orderTypeName = input.type?.name || ""
      }
      this.lowerLasttoothtocover =
        input.lower_left_lasttoothtocover +
        "-" +
        input.lower_right_lasttoothtocover
      this.upperLasttoothtocover =
        input.upper_left_lasttoothtocover +
        "-" +
        input.upper_right_lasttoothtocover
      this.shippingMethod = input?.shipping_method?.name
      this.shippingDate = input.shipping_date
      this.orderDate = input.order_date
      this.updateDate = input.update_date
      this.upperStageRange = input.upper_stage_range
      this.lowerStageRange = input.lower_stage_range
      this.upperRetainerStage = input.upper_retainer_stage
      this.lowerRetainerStage = input.lower_retainer_stage
      this.upperTemplateStage = input.upper_template_stage
      this.lowerTemplateStage = input.lower_template_stage
    }

    return this
  }
}
