import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"

import { UButton, UModalPopup, UText } from ".."

const NavigationBlockPopup: FC<{
  contentText?: string
  showPrompt: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
}> = ({ contentText, showPrompt, confirmNavigation, cancelNavigation }) => {
  const { t } = useTranslation()
  return (
    <UModalPopup
      isAlert={showPrompt}
      title={
        <UText
          variant={"h6"}
          sxProp={{
            width: "396px",
          }}
        >
          {t("newpatient.backbtnModal.title")}
        </UText>
      }
      sxModalProps={{
        "#titleCntr": {
          padding: "16px 24px",
        },
        "#contentCntr": {
          padding: "20px 24px",
          width: '444px',
        },
        "#btnListCntr": {
          padding: 1,
          gap: 1,
        },
      }}
      content={
        <UText variant={"body1"} sxProp={{ display: "block", minHeight: "32px" }}>
          <Trans components={{ bold: <b /> }}>
            {contentText
              ? contentText
              : t("popup.changesWillNotSave")} 
          </Trans>
        </UText>
      }
      btnList={[
        <UButton
          key={t("newpatient.backbtnModal.leavePage")}
          variant="shade"
          btnText={t("newpatient.backbtnModal.leavePage")}
          onClickHandler={confirmNavigation}
          sxProp={{
            width: 116,
            height: 36,
            boxShadow:
              "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
          }}
        ></UButton>,
        <UButton
          key={t("newpatient.backbtnModal.stayOnPage")}
          variant="contained"
          btnText={t("newpatient.backbtnModal.stayOnPage")}
          sxProp={{
            width: 136,
            height: 36,
          }}
          onClickHandler={cancelNavigation}
        ></UButton>,
      ]}
    ></UModalPopup>
  )
}

export default NavigationBlockPopup
