import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import logo from "../assets/images/ulablogo.svg"
import { Block } from "../components"
import { Alert, Box, Grid } from "../components/mui.components"
import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"
import { clearLocalStorage } from "../core/config/okta.config"
import { CENETER_COLUMN_ALIGN } from "../theme/theme.util"

const Auth: FC = () => {
  const { t } = useTranslation("common")
  const { showTokenExpired, successMessage, errorMessage } = useAppSelector(
    (state: RootState) => state.userService,
  )

  // clearLocalStorage()

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: "100%", py: "36px" }}
    >
      <Block
        component={"div"}
        sxProp={{
          ...CENETER_COLUMN_ALIGN,
          minWidth: "552px",
        }}
      >
        {((showTokenExpired && showTokenExpired.length) ||
          errorMessage.length > 0) && (
          <Alert
            sx={{
              mb: 3,
              backgroundColor: "#FDEDED",
              color: "#5F2120",
              ".MuiAlert-icon": {
                color: "error.main",
                alignItems: "center",
              },
              ".MuiAlert-message": {
                letterSpacing: "0.15px",
              },
              padding: "6px 16px",
            }}
            variant={"filled"}
            severity={"error"}
          >
            {showTokenExpired === "reset"
              ? t("auth.resetExpiredMsg")
              : showTokenExpired === "activation"
              ? t("auth.activationExpiredMsg")
              : errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert
            sx={{
              mb: 3,
              backgroundColor: "#e6fde7",
              color: "#205f28",
              ".MuiAlert-icon": {
                color: "success.main",
                alignItems: "center",
              },
              ".MuiAlert-message": {
                letterSpacing: "0.15px",
              },
              padding: "6px 16px",
            }}
            variant={"filled"}
            severity={"success"}
          >
            {successMessage}
          </Alert>
        )}
        <Box
          component={"img"}
          src={logo}
          alt={"Logo"}
          loading="lazy"
          sx={{ mb: 3 }}
        />
        <Outlet />
      </Block>
    </Grid>
  )
}

export default Auth
