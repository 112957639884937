import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Box, FormLabel } from "@mui/material"
import { useOktaAuth } from "@okta/okta-react"

import { FormInput, UButton, UText } from "../../../components"
import { useAppDispatch } from "../../../core/app/hooks"
import { IResetPassword } from "../../../core/app/slices/user/user.type"
import { resetPassword } from "../../../core/app/slices/user/userApis"
import {
  setErrorMessage,
  setSuccessMessage,
  updateTokenExpired,
} from "../../../core/app/slices/user/userSlice"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import ErrorAlert from "../components/ErrorAlert"
import PasswordInput from "../components/PasswordInput"

interface IResetPasswordForm {
  answer: string
  newPassword: string
  confirmPassword: string
  hasSixChar: boolean
  hasSpecialChar: boolean
}

const ResetPassword: FC = () => {
  const { t } = useTranslation("common")
  const { oktaAuth } = useOktaAuth()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const [searchParams] = useSearchParams()

  const [resetPasswordInfo, setResetPasswordInfo] = useState(null)
  const [errorMsg, setErrorMsg] = useState<string>("")
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm<IResetPasswordForm>({ mode: "onSubmit" })

  const newPasswordValue = watch("newPassword", "")

  const onSubmit: SubmitHandler<IResetPasswordForm> = async (data) => {
    setErrorMsg("")
    const payload: IResetPassword = {
      username: resetPasswordInfo.username,
      password: btoa(newPasswordValue),
      answer: data.answer,
      state_token: resetPasswordInfo.stateToken,
      okta_user_id: resetPasswordInfo.userId,
    }
    dispatch(resetPassword(payload)).then((response) => {
      if (response && response.payload) {
        if (response.payload?.response_code === 200) {
          dispatch(setSuccessMessage(t("auth.resetPassword.passwordResetSuccess")))
          navigate("/auth/login")
        } else if (response.payload?.data?.errors) {
          const errors = response.payload.data.errors
          if (errors && errors.length) {
            reset()
            if (errors[0]?.error_code === "E414") {
              setError("answer", {
                message: t("formfieldErrors.incorrectAnswer"),
              })
            } else {
              setErrorMsg(errors[0]?.error_message)
            }
          }
        }
      }
    })
  }

  useEffect(() => {
    if (searchParams.get("token")) {
      oktaAuth &&
        oktaAuth
          .verifyRecoveryToken({ recoveryToken: searchParams.get("token") })
          .then((response) => {
            if (response) {
              const { _embedded, stateToken } = response.data
              setResetPasswordInfo({
                securityQuestion: _embedded?.user?.recovery_question?.question,
                stateToken: stateToken,
                username: _embedded?.user?.profile?.login,
                userId: _embedded?.user?.id,
              })
            }
          })
          .catch((error) => {
            if (error?.errorCode === "E0000105") {
              dispatch(updateTokenExpired("reset"))
            } else {
              dispatch(setErrorMessage(error?.errorSummary))
            }
            navigate("/auth/login")
          })
    } else {
      navigate("/auth/login")
    }
  }, [searchParams, errorMsg])

  return (
    <>
      {resetPasswordInfo && resetPasswordInfo.securityQuestion && (
        <>
          {errorMsg && <ErrorAlert message={errorMsg} />}
          <UText
            variant={"h5"}
            color={"text.primary"}
            sxProp={{
              textAlign: "center",
              padding: "16px 0px",
            }}
          >
            {t("auth.resetPassword.title")}
          </UText>
          <Box
            component={"form"}
            data-testid={"reset-form"}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <UText variant={"h6"} color={"text.secondary"} component={"div"}>
                {t("auth.resetPassword.letsConfirmSubtitle")}
              </UText>
              <FormLabel id={"security-question"}>
                {resetPasswordInfo && resetPasswordInfo?.securityQuestion}
              </FormLabel>
              <FormInput
                aria-labelledby={"security-question"}
                fieldName={"answer"}
                rules={{
                  required: "formfieldErrors.answerRequired",
                }}
                errors={errors}
                control={control}
                formSxprops={{
                  m: 0,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <UText variant={"h6"} color={"text.secondary"}>
                {t("auth.resetPassword.newPasswordSubtitle")}
              </UText>
              <PasswordInput
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                formKey={"reset-form"}
                control={control}
                errors={errors}
                watch={watch}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <UButton
                sxProp={{ width: "100%" }}
                variant={"outlined"}
                btnType={"button"}
                btnText={t("auth.resetPassword.cancelBtn")}
                onClickHandler={() => navigate("login")}
              />
              <UButton
                sxProp={{ width: "100%" }}
                variant={"contained"}
                btnType={"submit"}
                btnText={t("auth.resetPassword.resetPasswordBtn")}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default ResetPassword
