import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { FormInput, UButton, UModalPopup, UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch } from "../../../core/app/hooks"
import { forgotPassword } from "../../../core/app/slices/user/userApis"
import { validations } from "../../../core/utils/validations"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"

const ForgotPasswordSuccess: FC = () => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  return (
    <>
      <Box
        component={"div"}
        sx={{ ...CENETER_COLUMN_ALIGN, textAlign: "center", color: "#000000" }}
      >
        <UText
          variant={"body1"}
          color={"inherit"}
          component={"div"}
          sxProp={{ mb: 4 }}
        >
          {t("auth.forgotpassowrdsuccess")}
        </UText>
        <UText variant={"body1"} color={"inherit"} component={"p"}>
          {t("auth.forgotpassowrdsuccesshelptext")}
        </UText>
        <UButton
          sxProp={{ mt: 4, width: "100%" }}
          variant={"contained"}
          btnText={t("button.back")}
          onClickHandler={() => navigate(-1)}
        />
      </Box>
    </>
  )
}

const ForgotPasswordSubmit: FC<{
  passwordResetSuccess: (val: boolean) => void
}> = ({ passwordResetSuccess }) => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const [closetabModal, setCloseTabModal] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ username: string }>({ mode: "onSubmit" })

  const onSubmit: SubmitHandler<{ username: string }> = async (data) => {
    if (data) {
      dispatch(
        forgotPassword({
          username: data.username,
        }),
      ).then((response) => {
        if (response && response?.payload?.status === "Success") {
          passwordResetSuccess(true)
        }
      })
    }
  }

  const closeTab = () => {
    // Attempt to close the tab
    window.close()

    // Check if the tab is still open after a short delay
    setTimeout(() => {
      if (!window.closed) {
        // If the tab is still open, show the modal
        setCloseTabModal(true)
      }
    }, 100)
  }

  return (
    <>
      {closetabModal && (
        <UModalPopup
          sxModalProps={{
            ".MuiDialog-paper": {
              minHeight: "fit-content",
            },
            "#contentCntr": {
              padding: "20px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={"Please close the current tab on your own."}
          isAlert={closetabModal}
          btnList={[
            <UButton
              key={"ok"}
              variant="contained"
              btnText={t("button.ok")}
              onClickHandler={() => setCloseTabModal(false)}
              sxProp={{
                width: 76,
                height: 36,
              }}
            />,
          ]}
        />
      )}
      <UText
        variant={"body1"}
        component={"div"}
        sxProp={{ color: "#000000", textAlign: "center" }}
      >
        {t("auth.forgotpasswordhelptext")}
      </UText>
      <Box
        component={"form"}
        sx={{ ...CENETER_COLUMN_ALIGN, width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          inputLabel={"formfields.email"}
          fieldName={"username"}
          rules={{
            required: "formfieldErrors.emailrequired",
            pattern: {
              value: validations.email.pattern.value,
              message: "formfieldErrors.userEmailinvalid",
            },
          }}
          errors={errors}
          control={control}
        />
        <Grid container direction={"row"} spacing={0} justifyContent={"center"}>
          <Grid item xs={12}>
            <UButton
              sxProp={{ mt: 2, width: "100%" }}
              btnType={"submit"}
              btnText={t("button.resetpassword")}
              variant={"contained"}
            />
          </Grid>
          <Grid item xs={12}>
            <UButton
              sxProp={{ mt: 2, width: "100%" }}
              btnText={t("button.close")}
              onClickHandler={closeTab}
              variant={"text"}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const ForgotOktaPassword: FC = () => {
  const { t } = useTranslation("common")
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] =
    useState<boolean>(false)
  return (
    <>
      <UText variant={"h5"} color={"text"} sxProp={{ mt: 2 }}>
        {t("auth.forgotpasswordtitle")}
      </UText>
      <Box
        component={"div"}
        sx={{ mt: 1, ...CENETER_COLUMN_ALIGN, " > div": { margin: "10px" } }}
      >
        {isPasswordResetSuccess ? (
          <ForgotPasswordSuccess />
        ) : (
          <ForgotPasswordSubmit
            passwordResetSuccess={setIsPasswordResetSuccess}
          />
        )}
      </Box>
    </>
  )
}

export default ForgotOktaPassword
