import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { setSmartRxShow } from "@/UDTreat/udTreatSlice"

import { UButton, UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { getBundleByCaseId } from "../../../core/app/slices/bundle/bundleThunkApi"
import { resetCasedata } from "../../../core/app/slices/case/caseSlice"
import {
  getCaseByPatient,
  getCaseFilesByCaseId,
  updateCaseStatus,
} from "../../../core/app/slices/case/caseThunkApi"
import { resetCase } from "../../../core/app/slices/clinical/clinicalSlice"
import { setRouteFrom } from "../../../core/app/slices/ConfigSlice"
import { getOrdersByCaseId } from "../../../core/app/slices/order/orderThunkApi"
import { addNewPatient } from "../../../core/app/slices/patients"
import { setData } from "../../../core/app/slices/patients/patientSlice"
import { resetPhotoLists } from "../../../core/app/slices/records/photograph/photographSlice"
import { resetXrays } from "../../../core/app/slices/records/xrays/xraysSlice"
import { RootState } from "../../../core/app/store"
import { ICase } from "../../../core/model/interface/ICase"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import usePermissions from "../../../hooks/userPermission"
import { CASE_STATUS } from "../config/status.config"
import { downloadZipFile } from "../PatientList/util/commonUtil"
import { navigateTo } from "../utils"

import CaseActionPopup from "./components/CaseActionPopup"
import CaseOverview from "./components/CaseOverview"
import { resetPrescriptionSchema, resetSchema } from "@/core/app/slices/uassist/formSlice"

const RenderByCaseStatus: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation()
  const { patientId } = useParams()
  const dispatch = useAppDispatch()
  const { caseList, caseListFetching } = useAppSelector(
    (state: RootState) => state.caseService,
  )
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const navigate = useMyNavigation()

  const [isAlertOpen, setIsAlertOpen] = useState<
    | "OPEN_CASE_ARCHIVE_MODEL"
    | "OPEN_ARCHIVE_PATIENT_MODEL"
    | "OPEN_CANCEL_MODEL"
    | "addNewCase"
    | "OPEN_ASSIGN_MODEL"
    | "OPEN_CASE_UNARCHIVE_MODEL"
    | ""
  >("")

  const [caseId, setCaseId] = useState("")

  useEffect(() => {
    dispatch(resetCasedata())
    dispatch(setRouteFrom(""))
  }, [dispatch])

  useEffect(() => {
    if (caseList.length > 0 && !caseList[0].assigned_to) {
      setIsAlertOpen("OPEN_ASSIGN_MODEL")
    }
  }, [caseList])

  const checkforActiveCase = () => {
    const payload = {
      firstname: patientData.firstName,
      lastname: patientData.lastName,
      dob: patientData.dob,
      patient_id: patientData.id,
      assigned_to: caseList[0]?.assigned_to || null,
    }
    dispatch(addNewPatient(payload)).then((res) => {
      if (res.payload?.data?.status === "Success") {
        dispatch(setData({ newPatientAPI: "idle" }))
        //refresh the same page
        window.location.href = `/overview/patient/${patientId}`
      } else {
        setIsAlertOpen("addNewCase")
      }
    })
  }

  const executeAction = (action: string, caseObj: ICase) => {
    setCaseId(caseObj.id.toString());
    dispatch(setRouteFrom("PATIENT_OVERVIEW_MODULE"))

    const resetData = () => {
      dispatch(resetXrays())
      dispatch(resetPhotoLists())
      dispatch(resetCase())
      dispatch(resetPrescriptionSchema())
      dispatch(resetSchema())
    }

    const downloadCaseFiles = (patientId: number, caseId: number) => {
      dispatch(
        getCaseFilesByCaseId({ patientId: patientId, caseId: caseId }),
      ).then((res) => {
        downloadZipFile(res.payload.data, caseId)
      })
    }

    switch (action) {
      case "OPEN_RECORDS":
        navigateTo("RECORDS", navigate, patientId, caseObj.id)
        break
      case "OPEN_PRESCRIPTION_FORM":
        resetData()
        navigateTo("PRESCRIPTION", navigate, patientId, caseObj.id, caseObj.udesign_json)
        break
      case "OPEN_SUMMARY":
        navigateTo("SUMMARY", navigate, patientId, caseObj.id)
        break
      case "OPEN_RETAINER_FORM":
        navigateTo("RETAINER", navigate, patientId, caseObj.id)
        break
      case "START_REFINEMENT":
        resetData()
        navigateTo("REFINEMENT", navigate, patientId, caseObj.id)
        break
      case "OPEN_ARCHIVE_PATIENT_MODEL":
      case "OPEN_CASE_ARCHIVE_MODEL":
      case "OPEN_CANCEL_MODEL":
      case "OPEN_CASE_UNARCHIVE_MODEL":
        setIsAlertOpen(action)
        break
      case "ADD_NEW_CASE_MODEL":
        dispatch(setRouteFrom(""))
        checkforActiveCase()
        break
      case "OPEN_TREAT_PAGE":
        navigateTo("TREAT", navigate, patientId, caseObj.id)
        break
      case "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS":
        dispatch(
          updateCaseStatus({
            patientId: patientId.toString(),
            caseId: caseObj.id.toString(),
            payload: {
              case_disposition: CASE_STATUS.TREATMENT_IN_PROGRESS,
            },
          }),
        ).then((res) => {
          if (res && res.payload && res.payload.response_code === 200) {
            dispatch(getCaseByPatient({ patientId: patientId }))
            dispatch(getOrdersByCaseId(caseObj.id))
            dispatch(getBundleByCaseId(caseObj.id))
          }
        })
        break
      case "UPDATE_STATUS_IN_PROGRESS_TO_COMPLETE":
        dispatch(
          updateCaseStatus({
            patientId: patientId.toString(),
            caseId: caseObj.id.toString(),
            payload: {
              case_disposition: CASE_STATUS.TREATMENT_COMPLETE,
            },
          }),
        ).then((res) => {
          if (res && res.payload && res.payload.response_code === 200) {
            dispatch(getCaseByPatient({ patientId: patientId }))
            dispatch(getOrdersByCaseId(caseObj.id))
            dispatch(getBundleByCaseId(caseObj.id))
          }
        })
        break
      case "DOWNLOAD_FILES":
        downloadCaseFiles(Number(patientId), caseObj?.id)
        break
      case "UNLOCK_CASE":
        setIsAlertOpen(action)
        break
      default:
        console.log(action, "..action")
    }
  }

  return (
    <>
      {!!caseList.length &&
        caseList?.map((caseObj: ICase, index: number) => (
          <Grid item mb={5} key={index}>
            <CaseOverview
              index={index}
              caseObj={caseObj}
              setIsAlertOpen={setIsAlertOpen}
              executeAction={executeAction}
            />
          </Grid>
        ))}

      {!caseList.length && (
        <Grid item mb={5}>
          <Grid
            item
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            sx={{ mb: 3 }}
          >
            {hasAny(UI_PERMISSION.UI_TXPLANS_CREATE) && (
              <UButton
                variant="text"
                disabled={patientData?.status === "ARCHIVED"}
                onClickHandler={() => {
                  checkforActiveCase()
                }}
                sxProp={{
                  height: 30,
                  width: 113,
                  padding: "5px",
                  fontSize: "13px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                btnText={t("overview.buttons.addNewCaseBtn")}
              />
            )}
            {hasAny(UI_PERMISSION.UI_TXPLANS_CLINICALVIEW) && (
              <UButton
                variant="contained"
                disabled={
                  patientData?.status === "ARCHIVED" || !caseList.length
                }
                sxProp={{
                  height: 30,
                  width: 95,
                  padding: "4px 10px",
                  fontSize: "13px",
                  ml: 2,
                }}
                btnText={t("overview.buttons.openCaseBtn")}
                onClickHandler={() => {
                  console.log("NO Action Required for")
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Box
              sx={{
                padding: "24px 40px",
                minHeight: "220px",
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "16px",
                mb: 3,
              }}
            >
              {caseListFetching === "succeeded" && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UText variant="body1" sxProp={{ color: "text.secondary" }}>
                    {"Please add a new case"}
                  </UText>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <CaseActionPopup
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        caseId={caseId}
      />
    </>
  )
}

export default RenderByCaseStatus
