import { OrderQueryparams } from "../../../../core/app/slices/patients/patient.types"
import http from "../../../global/http"
import { ShippingAddressTo } from "../../../model/Address"
import {
  IAlignerOrder,
  IAlignerOrderPayload,
} from "../../../model/interface/AlignerOrder"
import { IActiveOrders, IOrder } from "../../../model/interface/IOrder"
import { IShippingAddressTo } from "../../../model/interface/IShippingAddress"
import { ActiveOrders, AlignerOrder, Order, UAssistOrder, IUAssistOrder } from "../../../model/Order"
import { Quotation } from "../../../model/Quotation"
import { clinicalRequestBasicType } from "../clinical/clinical.types"

export const updateOrderStatus = async (orgId: string, payload: any) => {
  try {
    const { data } = await http.post(
      `/order-mgmt/v2/org/${orgId}/orders`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getOrdersByCase = async (orgId: string, caseId: number) => {
  try {
    const orders: IOrder[] = []
    const { data } = await http.get(
      `/order-mgmt/v2/org/${orgId}/orders/txplans/${caseId}`,
    )
    if (
      data &&
      data.response_code === 200 &&
      data.result &&
      data.result.length > 0
    ) {
      data.result.forEach((r) => {
        orders.push(new Order().Deserialize(r))
      })
    }

    return orders
  } catch (err) {
    return err
  }
}

export const getQuotationService = async (orgId: string, payload: any) => {
  try {
    const { data } = await http.post(`/order-mgmt/v1/org/${orgId}/quotation/`, {
      ...payload,
    })

    if (data && data.result) {
      return data.result
    }
    return {}
  } catch (err) {
    return err
  }
}

export const placeAlignerOrder = async (
  orgId: string,
  payload: IAlignerOrderPayload,
) => {
  // to be removed once bundle, stage and shipping address sections are ready
  try {
    let alignerObj: IAlignerOrder = {} as IAlignerOrder
    const { data } = await http.post(`/order-mgmt/v2/org/${orgId}/orders`, {
      ...payload,
    })
    if (data) {
      alignerObj = new AlignerOrder().Deserialize(data.result)
    }
    return alignerObj
  } catch (err) {
    return err
  }
}

export const placeManufacturingOrder = async (
  orgId: string,
  orderId: string,
  payload: {
    sub_orders: number[]
    status: number
    txplan_name: string
    txplan_subname: string
  },
) => {
  try {
    return await http.patch(
      `/order-mgmt/v2/org/${orgId}/orders/${orderId}`,
      payload,
    )
  } catch (err) {
    return err
  }
}

export async function getStageDetails({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { result, status },
  } = await http.get(
    `/patient-mgmt/v3/org/${orgId}/patients/${patientId}/txplans/${caseId}/stage-details`,
  )
  return { results: result, status }
}

export const getShippingAddress = async (orgId: string, userId: string) => {
  const url = `/user-mgmt/v1/org/${orgId}/users/${userId}/?fields=shipping_address,org_name`
  try {
    const { data } = await http.get(url)
    let address = {} as IShippingAddressTo
    if (data && data.result && data.result.shipping_address) {
      address = new ShippingAddressTo().Deserialize(data.result)
    }
    return address
  } catch (err) {
    return err
  }
}

export const getPackageDetails = async (orgId: string) => {
  const url = `/user-mgmt/v1/org/${orgId}/account/details/`
  try {
    const { data } = await http.get(url)
    return data
  } catch (err) {
    return err
  }
}

export const createAlternateShippingAddress = async (
  orgId: string,
  userId: string,
  payload: IShippingAddressTo,
) => {
  const url = payload.id
    ? `/user-mgmt/v2/org/${orgId}/users/${userId}/address/${payload.id}/`
    : `/user-mgmt/v2/org/${orgId}/users/${userId}/address/`

  try {
    const { data } = payload.id
      ? await http.put(url, payload)
      : await http.post(url, payload)
    return data
  } catch (err) {
    return err
  }
}

export const getMaterials = async ({ patientId, caseId, orgId }) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/material`
  try {
    const {
      data: { result, status },
    } = await http.get(url)
    return result
  } catch (err) {
    return err
  }
}

export const getActiveOrders = async (
  orgId: string,
  tableQueryParams: OrderQueryparams,
) => {
  try {
    const activeOrders: IActiveOrders[] = []
    const rs: any = await http.post(
      `/order-mgmt/v2/org/${orgId}/orders/search?page=${tableQueryParams.page}`,
      tableQueryParams,
    )
    if (rs && rs.status === 200) {
      const { paging, result } = rs.data
      if (result && result.length > 0) {
        result.forEach((element) => {
          activeOrders.push(new ActiveOrders().Deserialize(element))
        })
      }
      return {
        totalRecords: paging.total_count,
        currentPage: paging.current_page,
        pageSize: paging.page_size,
        records: activeOrders,
      }
    }
  } catch (err) {
    return err
  }
}

export const getCustomPackagePricing = async (orgId: string) => {
  const url = `/order-mgmt/v1/org/${orgId}/quotation/custom_box/`
  try {
    const { data } = await http.post(url, {
      action: "custombox",
      org_id: orgId,
      items: {
        "custom-box": 1,
        "custom-box-artwork": 1,
      },
    })
    return data
  } catch (err) {
    return err
  }
}

export const fetchShippingMethods = async (orgId: string, payload: any) => {
  const url = `/order-mgmt/v1/org/${orgId}/orders/shipping-methods/`
  try {
    const rs = await http.post(url, {
      ...payload,
    })
    if (rs.data && rs.data.response_code === 200) {
      return rs.data.result
    }
    console.log(".fetchShippingMethods...", rs)
    return []
  } catch (err) {
    return err
  }
}

export const downloadUassistSummary = async (orgId: string, orderId: string) => {
  const url = `/order-mgmt/v2/org/${orgId}/orders/${orderId}/uassist-summary/`
  try {
    const response = await http.get(url, {
      responseType: 'blob', // Important for handling binary data
    })
    // Extract the filename from the content-disposition header
    const contentDisposition = response.headers['content-disposition']
    let filename = `uassist-summary-${orderId}.pdf`
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?(.+)"?/)
      if (match && match[1]) {
        filename = match[1]
      }
    }
    const fileurl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = fileurl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
    return fileurl
  } catch (err) {
    console.error('Error downloading PDF:', err)
    throw err
  }
}

export const fetchUAssistOrders = async (orgId: string, patientId: string, caseId: string) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/uassist-orders`
  try {
    const rs = await http.get(url)
    if (rs.data && rs.data.response_code === 200) {
      const result = rs.data.result

      let constructedResult: Array<IUAssistOrder> = []

      result.orders.forEach((element) => {
        constructedResult.push(new UAssistOrder().Deserialize(element))
      })

      return constructedResult
    }
    return []
  } catch (err) {
    return err
  }
}
