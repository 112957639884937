import React, { useRef, useEffect } from 'react';
 
const StageMoreIcon = (props) => {
  const canvasRef = useRef(null)
  const position = (props.position === undefined || props.position === null ? "unset" : props.position)
  const left = (props.left === undefined || props.left === null ? "unset" : (props.left - 6))
  const top = (props.top === undefined || props.top === null ? "unset" : (props.top))


  const drawImg = () => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, rect.width, rect.height)
    if(props.visibility === "hidden") {
      return
    }

    // 绘制一个+
    ctx.strokeStyle = '#02AFEC'//'#02AFEC'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.moveTo(rect.width / 2, 0)
    ctx.lineTo(rect.width / 2, rect.height)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(0, rect.height / 2)
    ctx.lineTo(rect.width, rect.height / 2)
    ctx.stroke()
  }
 
  useEffect(() => {
    drawImg()
  }, [])

  useEffect(() => {
    drawImg()
  }, [props.visibility])
 
  return (
    <canvas ref={canvasRef} width={'14px'} height={'14px'} onClick={() => {if(props.visibility === "") {props.onClick()}}} style={{width: '14px', height: '14px', minWidth: '14px', maxWidth: '14px', minHeight: '14px', maxHeight: '14px', position: position, left: left, top: top}}></canvas>
  )
}
 
export default StageMoreIcon;