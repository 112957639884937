import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, TableCell, TableRow } from "@mui/material"

import { useMyNavigation } from "../../../../../hooks/useMyNavigation"
import { UButton, UText } from "../../../../../ui-component"
import { useAppSelector } from "../../../../../core/app/hooks"
import { RootState } from "../../../../../core/app/store"

export interface IEmptyTableContentProps {
  isEmpty: boolean
  clearFilterHandler: () => void | undefined
}

const EmptyTableContent: FC<IEmptyTableContentProps> = ({
  isEmpty,
  clearFilterHandler,
}) => {
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")

  const { user } = useAppSelector(
    (state: RootState) => state.userService,
  )

  return (
    <TableRow data-testid={"emptyTableContent"}>
      <TableCell colSpan={100} sx={{ padding: "0", borderBottom: "none" }}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            padding: "80px 10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: isEmpty ? "16px" : "8px",
            flexBasis: "100%",
          }}
        >
          <UText
            variant={"body1"}
            color={isEmpty ? "text.primary" : "text.secondary"}
          >
            {isEmpty && !user.isAllowToViewAllOrg
              ? t("patientList.createSmileSubtitle")
              : t("patientList.noResult")}
          </UText>
          {!isEmpty && (
            <UText variant={"body2"} color={"text.secondary"}>
              {isEmpty
                ? t("patientList.createSmileSubtitle")
                : t("patientList.clearFiltersMessage")}
            </UText>
          )}
          <Box
            component={"div"}
            sx={{
              paddingTop: !isEmpty ? "8px" : "0px",
            }}
          >
            {!user.isAllowToViewAllOrg && 
            <UButton
            sxProp={{
              border: "1px solid rgba(33, 150, 243, 0.50)",
              padding: "8px 22px",
              fontSize: "15px",
              lineHeight: "173.333%",
              letterSpacing: "0.46px",
              color: "primary.main",
            }}
            size={"large"}
            variant={"outlined"}
            btnType={"button"}
            btnText={
              !isEmpty
                ? t("records.button.clearFilterBtn")
                : t("records.button.addNewPatient")
            }
            onClickHandler={() => {
              isEmpty ? navigate("/patients/new") : clearFilterHandler()
            }}
          />
           }
            
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default EmptyTableContent
